import Avatar from 'components/Avatar';
import logo from 'assets/images/logo.png';
import React from 'react';
import {
  MdNotificationsActive,
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink
} from 'reactstrap';
import bn from 'utils/bemnames';
import GenerateSurvey from '../GenerateSurvey';

const navItems = [
  { to: '/profile', name: '', exact: false }
];
const bem = bn.create('header');

class Header extends React.Component {

  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
    openSideMenu: true,
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
    isServey: false,
    isGenerateVisible: false
  };

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    const htmlElement = document.querySelector('.cr-sidebar');
    if (this.state.openSideMenu) {
      htmlElement.classList.remove('cr-sidebar--open');
      this.setState({ openSideMenu: false })
    } else {
      htmlElement.classList.add('cr-sidebar--open');
      this.setState({ openSideMenu: true })
    }
  };

  openGenerateSurvey = () => {
    this.setState({ isGenerateVisible: true });
  };

  hideModal = () => {
    this.setState({ isGenerateVisible: false });
  }


  render() {
    const { isNotificationConfirmed } = this.state;

    let sideBar = null

    if (this.props.userData.role === 'Admin') {
      sideBar = (
        <NavItem className="d-inline-flex">
          <div className="nav-link">
            <Button color="secondary" size="sm" onClick={this.openGenerateSurvey}>
              GENERATE SURVEY
            </Button>
          </div>
        </NavItem>
      )
    }

    return (
      <React.Fragment >
        <Navbar light expand className={bem.b('bg-white')}>
          <div className={`navigationIcon ${this.state.openSideMenu ? 'position-fixed' : 'navCollaps'}`}>
            <div className={`menu menu-icon ${this.state.openSideMenu ? '' : 'open'}`} onClick={this.handleSidebarControlButton}>
              <div className="icon"></div>
            </div>
          </div>
          <Nav navbar>
            <div className="logo">
            <a className="nav-link" href="/">
              <img src={logo} /> 
            </a>
            </div>
          </Nav>

          <Nav navbar className={bem.e('nav-right')}>
            {sideBar}

            {navItems.map(({ to, name, exact }, index) => (
              <NavItem key={index}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact} >
                  <div className="avatar">{this.props.userData.firstName.charAt(0) + this.props.userData.lastName.charAt(0)}</div>
                  {/* <Avatar className="can-click" >TS</Avatar> */}
                </BSNavLink>
              </NavItem>
            ))}
          </Nav>
        </Navbar>
        <GenerateSurvey hideModal={this.hideModal} isGenerateVisible={this.state.isGenerateVisible} className="GenerateSurveyPage" />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    userData: state.auth.userData
  }
}

export default connect(mapStateToProps)(Header);