import * as actions from '../actions/actionTypes';

const initialState = {
    isLoading: false,
    error: null,
    users: null,
    managers: []
}

const users = (state = initialState, action) => {
    switch (action.type) {
        case actions.GET_USERS_SUCCESS:
            return {
                isLoading: false,
                users: action.users,
                managers: [...state.managers],
                error: null
            }
        case actions.PROJECT_GET_MANAGERS:
                const managers = [{value: '', displayValue: 'Select manager', disabled: true, selected: true}]
                for (let manager of action.managers) {
                    managers.push({value: manager.fuid, displayValue: manager.firstName + ' ' + manager.lastName})
                }
                
                return {
                    users: {...state.users},
                    managers: managers
                }
        default:
            return state
    }
}

export default users;