import * as actions from './actionTypes';
import axios from 'axios'

const getAllUsersSuccess = (users) => {
    return {
        type: actions.GET_USERS_SUCCESS,
        users: users
    }
}

export const getAllUsers = () => {
    /*return dispatch => {
        axios.get('https://khushiapp-dev.firebaseio.com/users.json')
        .then(res => {
            console.log(res)
            dispatch(getAllUsersSuccess(res.data))
        })
        .catch(err => {
            console.log(err)
        })
    }*/
}

const updateManagers = (users) => {
    return {
        type: actions.PROJECT_GET_MANAGERS,
        managers: users
    }
}

export const profileGetManager = () => {
    return (dispatch,getState,{
        getFirebase
      }) => {
        const firestore = getFirebase().firestore();
        //const queryParam = '?orderBy="role"&equalTo="manager"';
        //axios.get('https://khushiapp-dev.firebaseio.com/users.json' + queryParam)
        //firestore.collection("users").where("role", "==", "Manager").get()
        //firestore.collection("users").where("role", "==", "Manager").where("isActive", 'in', [true, "1"]).get()
        firestore.collection("users").where("role", "in", ["Admin","Manager"]).where("isActive", '==', true).get()
        .then(function(querySnapshot) {
            let users = []
            querySnapshot.forEach(function(doc) {
                const user = {}
                user['fuid'] = doc.id
                user['firstName'] = doc.data().firstName
                user['lastName'] = doc.data().lastName
                users.push(user)
            });

            dispatch(updateManagers(users))
        }
   )
        .catch(error => {
            console.log(error);
        })
    }
}