import React, { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
// import { removeQuestion } from "../../store/actions/questionActions";
import { editQuestion } from '../../store/actions/questionActions';
import {
  Card,
  CardBody,
  CardHeader,
  CardImg,
  CardImgOverlay,
  CardLink,
  CardText,
  CardTitle,
  Col,
  ListGroup,
  ListGroupItem,
  ButtonGroup,
  Row,
  Badge,
  Alert,
  UncontrolledAlert,
  Progress,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
} from 'reactstrap';

const Question = props => {
  const [modal, setModal] = useState(false);
  const [state, setState] = React.useState({
    id: props.question.id,
    title: props.question.title,
    description: props.question.description,
    modal: false,
    category: '',
    createdBy: 'admin',
    createdOn: new Date(),
  });
  // const handleRemove = (question) => {
  //   removeQuestion(question);
  // };
  const handleUpdate = (question, id) => {
    //console.log(question);
    // let id =  question.id;
    setState({
      id: props.question.id,
      title: props.question.title,
      description: props.question.description,
      modal: false,
      category: '',
      createdBy: 'admin',
      createdOn: new Date(),
    })
    editQuestion(question, question.id);
    setModal(true);
  };
  const handleSubmit = e => {
    e.preventDefault();
    // //console.log(this.state);
    //console.log(state);
    props.updateQuestion(state);
    // document.getElementById("addQuestionForm").reset();
    setModal(false);
  };
  const handleChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
    // //console.log(state)
  };
  const toggle = modalType => () => {
    //console.log(modalType);
    if (!modalType) {
      setModal(false);
    }

    // setModal(true)
    // this.setState({
    //   [`modal_${modalType}`]: !this.state[`modal_${modalType}`],
    // });
  };
  
  return (
    <>
      <tr key={props.question.id}>
        <td>{props.question.title}</td>
        <td>{props.question.description}</td>
        <td>
          {moment(props.question.createdOn.toDate()).format('DD-MMM-YYYY')}
        </td>
        <td>
          <span
            className="btn btn-sm btn-outline-primary"
            style={{ cursor: 'pointer' }}
            onClick={() => handleUpdate(props.question, props.question.id)}
          >
            Edit
          </span>
        </td>
      </tr>
      <Modal isOpen={modal} toggle={toggle()} className="sideModal">
        <form className="white" id="addQuestionForm" onSubmit={handleSubmit}>
          <ModalHeader toggle={toggle()}>
            <div className="modal-title">
              <p className="mb-0">UPDATE QUESTIONS</p>
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="container">
              <div className="form-group">
                <label htmlFor="title">Title</label>
                <input
                  className="form-control"
                  type="text"
                  name="title"
                  id="title"
                  value={state.title}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea
                  className="form-control"
                  name="description"
                  id="description"
                  value={state.description}
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="form-group">
              {state.title === "" ?
                (<button
                  className="btn-darkyellow btn btn-primary btn-sm"
                  disabled
                >
                  Update
                </button>):
                (<button
                  className="btn-darkyellow btn btn-primary btn-sm"
                  onClick={handleSubmit}
                >
                  Update
                </button>)
              }
              <button
                type="button"
                className="btn btn-outline-primary btn-sm ml-3"
                onClick={toggle()}
              >
                Cancel
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    // removeQuestion: (question) => dispatch(removeQuestion(question)),
    updateQuestion: (question, id) => dispatch(editQuestion(question, id)),
  };
};

export default connect(null, mapDispatchToProps)(Question);
