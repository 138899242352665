import * as actions from '../actions/actionTypes'
const initialState = {
    userFeedbackData: [],
    projectFeedbackData: [],
    projectTeamCount: 0,
    isTeamCountAddedSuccess: false,
    orgFeedbackData: [],
    shouldRefresh: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.GET_USER_FEEDBACK:
            return {
                ...state,
                userFeedbackData: action.userFeedbackData
            }
        case actions.GET_PROJECT_FEEDBACK:
            return {
                ...state,
                projectFeedbackData: action.projectFeedbackData
            }
        case actions.GET_PROJECT_TEAM_COUNT:
            return {
                ...state,
                projectTeamCount: action.count
            }
        case actions.ADD_TEAM_COUNT_SUCCESS:
            return {
                ...state,
                isTeamCountAddedSuccess: true
            }
        case actions.CREATE_COUNT_RESET:
            return {
                ...state,
                isTeamCountAddedSuccess: false
            }
        case actions.GET_ORG_FEEDBACK:
            return {
                ...state,
                orgFeedbackData: action.orgFeedbackData,
                orgResponseSumData: action.orgResponseSumData
            }
        case actions.REFRESH_DASHBOARD:
            return {
                ...state,
                shouldRefresh: true
            }
        case actions.RESET_REFRESH_DASHBOARD:
            return {
                ...state,
                shouldRefresh: false
            }
        default:
            return state
    }
}

export default reducer;