import AuthForm, { STATE_LOGIN } from 'components/AuthForm';
import React from 'react';
import { connect } from 'react-redux';
import { authStart } from '../store/actions/auth';
import { Card, Col, Row } from 'reactstrap';
import Page from 'components/Page';
// import NET from 'vanta/dist/vanta.net.min'

class AuthPage extends React.Component {
  constructor() {
    super()
    // this.vantaRef = React.createRef()
  }
  handleAuthState = authState => {
    if (authState === STATE_LOGIN) {
      this.props.history.push('/login');
    } else {
      this.props.history.push('/signup');
    }
  };

  handleLogoClick = () => {
    this.props.history.push('/');
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.authStart()
  };

  emailLogin = event => {
    event.preventDefault();
    this.props.history.push('/emailPassword')
  }

  componentDidMount() {
    // this.vantaEffect = NET({
    //   el: this.vantaRef.current,
    //   mouseControls: true,
    //   touchControls: true,
    //   gyroControls: false,
    //   minHeight: 200.00,
    //   minWidth: 200.00,
    //   scale: 1.00,
    //   scaleMobile: 1.00,
    //   color: 0xf7f0ff,
    //   backgroundColor: 0x552682,
    //   points: 12.00
    // })
  }
  componentWillUnmount() {
    // if (this.vantaEffect) this.vantaEffect.destroy()
  }

  render() {
    return (
      <Page
        className="LoginPage"
      >
        <Row className="loginContent justify-content-center align-items-center">
          <Col className="loginBox mb-0">
            <Card body className="justify-content-center">
              <AuthForm
                authState={this.props.authState}
                onChangeAuthState={this.handleAuthState}
                onLogoClick={this.handleLogoClick}
                handleSubmit={this.handleSubmit}
                emailLogin={this.emailLogin}
              />
            </Card>
          </Col>
        </Row>
        {/* <div className="bg-animation" ref={this.vantaRef}>
          &nbsp;
        </div> */}

      </Page>
    );
  }
}

const mapStateToProps = state => {
  return {
      token: state.auth.token,
      userData: state.auth.userData
  }
}

const mapDispatchToProps = dispatch => {
  return {
      authStart: () => dispatch(authStart())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);
