const initState = {
    isSuccess: false,
    isFailure: false
}

const feedbackReducer = (state = initState, action) => {
    switch (action.type) {
        case 'GENERATE_FEEDBACK_SUCCESS':
            initState.isSuccess = true
            initState.isFailure = false
            //console.log('generate feedback success');
            return state;
        case 'GENERATE_FEEDBACK_ERROR':
            initState.isSuccess = false
            initState.isFailure = true
            //console.log('generate feedback error');
            return state;
        case 'GENERATE_FEEDBACK_RESET':
            initState.isSuccess = false
            initState.isFailure = false
            return state;
        default:
            return state;
    }
};

export default feedbackReducer;