import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createQuestion } from '../../store/actions/questionActions'
import { Redirect } from 'react-router-dom'
import { Container, Table, Button, Form } from 'react-bootstrap';
import {
  Card,
  CardBody,
  CardHeader,
  CardImg,
  CardImgOverlay,
  CardLink,
  CardText,
  CardTitle,
  Col,
  ListGroup,
  ListGroupItem,
  ButtonGroup,
  Row,
  Badge,
  Alert,
  UncontrolledAlert,
  Progress,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
} from 'reactstrap';


class CreateQuestion extends Component {
  state = {
    modal: false,
    title: '',
    description: '',
    category:'',
    createdBy: 'admin'
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();
    // console.log(this.state);
    this.props.createQuestion(this.state);
    document.getElementById("addQuestionForm").reset();
    //console.log(this.state);
    this.setState({
      modal: false,
    });
  }
  toggle = modalType => () => {
    if (!modalType) {
      return this.setState({
        modal: !this.state.modal,
      });
    }
    
    this.setState({
      [`modal_${modalType}`]: !this.state[`modal_${modalType}`],
    });
  };
  
  render() {
    return (
      <>
        <Button className="btn-darkyellow mb-3" size="sm" onClick={this.toggle()}>Add Questions</Button>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle()}
          className={this.props.className}>
          <form className="white" id="addQuestionForm" onSubmit={this.handleSubmit}>
            <ModalHeader toggle={this.toggle()}>
            <div className="modal-title"><p className="mb-0">ADD QUESTIONS <span className="d-block">add questions to the repository</span></p></div>
            </ModalHeader>
            <ModalBody>
              <div className="container">
                <div className="form-group">
                  <label htmlFor="title">Title</label>
                  <input className="form-control" type="text" name="title" id='title' onChange={this.handleChange} />
                </div>
                <div className="form-group">
                  <label htmlFor="description">Description</label>
                  <textarea className="form-control" name="description" id="description" onChange={this.handleChange}></textarea>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="form-group">
                {this.state.title === ""  ?
                (<button className="btn-darkyellow btn btn-primary btn-sm" disabled>Create</button>):
                (<button className="btn-darkyellow btn btn-primary btn-sm">Create</button>)
                }
                <button type="button" className="btn btn-outline-primary btn-sm ml-3" onClick={this.toggle()}>Cancel</button>
              </div>
            </ModalFooter>
          </form>
        </Modal>
      </>
    )
  }
}



const mapDispatchToProps = dispatch => {
  return {
    createQuestion: (question) => dispatch(createQuestion(question))
  }
}

export default connect(null, mapDispatchToProps)(CreateQuestion)
