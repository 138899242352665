import firebase from 'firebase';
import moment from "moment";
import * as actions from './actionTypes';
import { toast } from "react-toastify";

const updateUserFeedback = (feedbackStat) => {
    return {
        type: actions.GET_USER_FEEDBACK,
        userFeedbackData: feedbackStat
    }
}

const updateProjectFeedback = (feedbackStat) => {
    return {
        type: actions.GET_PROJECT_FEEDBACK,
        projectFeedbackData: feedbackStat
    }
}
const updateOrgFeedback = (feedbackStat, responseExpected) => {
    return {
        type: actions.GET_ORG_FEEDBACK,
        orgFeedbackData: feedbackStat,
        orgResponseSumData : responseExpected
    }
}

const getLastSixMonths = () => {
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
    var today = new Date();
    var d;
    var year;
    var month;

    let monthsEmptyData = []
  
    for(var i = 6; i > 0; i -= 1) {
      d = new Date(today.getFullYear(), today.getMonth() - i, 1);
      year = d.getFullYear()
      month = monthNames[d.getMonth()];
      const keyName = month + ' ' + year
      monthsEmptyData[keyName] = { relevant: 0, meh: 0, sad: 0, total: 0, chartValue: 0, percentage: 0 }
    }

    return monthsEmptyData
}

const getFormattedData = (querySnapshot, count = -1) => {
    
    const groupedResponses = getLastSixMonths()
    querySnapshot.forEach(function (doc) {

        /*if (Object.keys(groupedResponses).length > 5) {
            return groupedResponses
        }*/

        const projMonthYear = doc.data().surveyName.split("-");
        const monthYear = projMonthYear[projMonthYear.length-2] + ' ' + projMonthYear[projMonthYear.length-1]

        let happyResponsesCount = doc.data().questionAnswer.filter(feedback => feedback.answer === 'happy').length
        let mehResponsesCount = doc.data().questionAnswer.filter(feedback => feedback.answer === 'meh').length
        let adjustedMehResponsesCount = mehResponsesCount/2
        let sadResponsesCount = doc.data().questionAnswer.filter(feedback => feedback.answer === 'sad').length
        let totalResponsesCount = doc.data().questionAnswer.length

        if (count > -1) {
            totalResponsesCount = doc.data().questionAnswer.length * count
        }

        if (monthYear in groupedResponses) {
            happyResponsesCount = happyResponsesCount + groupedResponses[monthYear].relevant
            if (count === -1) {
                totalResponsesCount = totalResponsesCount + groupedResponses[monthYear].total
            }

            mehResponsesCount = groupedResponses[monthYear].meh + mehResponsesCount
            sadResponsesCount = groupedResponses[monthYear].sad + sadResponsesCount
            adjustedMehResponsesCount = mehResponsesCount/2

            let happinessQuotient = Math.round(((happyResponsesCount + adjustedMehResponsesCount) / totalResponsesCount) * 100)
            let percentage = happinessQuotient
            if (happinessQuotient >= 80) {
                happinessQuotient = 3
            } else if (happinessQuotient >= 50 && happinessQuotient < 80) {
                happinessQuotient = 2
            } else if (happinessQuotient < 50) {
                happinessQuotient = 1
            }

            

            groupedResponses[monthYear] = { relevant: happyResponsesCount, meh: mehResponsesCount, sad: sadResponsesCount, total: totalResponsesCount, chartValue: happinessQuotient, percentage: percentage }
        
        }
    });

    // //console.log("groupedResponses", groupedResponses)
    return groupedResponses

}


export const getUserFeedback = (userEmail, project) => {
    return (dispatch, getState, {
        getFirebase
    }) => {
        const firestore = getFirebase().firestore();
        firestore.collection("feedback").where("answeredBy", "==", userEmail).where("surveyProject", "==", project).orderBy("createdOn", "desc")
            .get()
            .then(function (querySnapshot) {
                const allFeedbackStats = getFormattedData(querySnapshot)
                dispatch(updateUserFeedback(allFeedbackStats))
            })
            .catch(error => {
                //console.log(error);
            })
    }
}

export const getProjectFeedback = (project, teamCount) => {
    return (dispatch, getState, {
        getFirebase
    }) => {
        const firestore = getFirebase().firestore();
        firestore.collection("feedback").where("surveyProject", "==", project).orderBy("createdOn", "desc")
            .get()
            .then(function (querySnapshot) {
                const allFeedbackStats = getFormattedData(querySnapshot, teamCount)
                // //console.log("allFeedbackStats",allFeedbackStats)
                dispatch(updateProjectFeedback(allFeedbackStats))
            })
            .catch(error => {
                //console.log('error ', error);
            })
        }
}

export const getProjectTeamCount = (project, month, year) => {
    return (dispatch, getState, {
        getFirebase
    }) => {
        const firestore = getFirebase().firestore();
        firestore.collection("teamCount").where("projectName", "==", project).where("month", "==", month).where("year", "==", Number(year))
            .get()
            .then(function (querySnapshot) {
                let projCount = 0
                querySnapshot.forEach(function (doc) {
                    // console.log('teamCount data ',doc.data())
                    projCount = doc.data().count
                })

                dispatch({type: 'GET_PROJECT_TEAM_COUNT', count: projCount})
            })
            .catch(error => {
                //console.log('error ', error);
            })
        }
}

export const addProjectTeamCount = (teamCountData) => {
    return (dispatch, getState, {
        getFirebase
    }) => {
        const firestore = getFirebase().firestore();
        firestore.collection("teamCount")
            .add(teamCountData)
            .then(function(docRef) {
                //userData.fuid = docRef.id
                dispatch({type: 'ADD_TEAM_COUNT_SUCCESS'})
                toast.success('Team count succesfully added');
            })
            .catch(error => {
                //console.log('error ', error);
            })
        }
}

export const updateProjectTeamCount = (teamCountData) => {
    return (dispatch, getState, {
        getFirebase
    }) => {
        //console.log('teamCountData.id ',teamCountData)
        const firestore = getFirebase().firestore();
        firestore.collection("teamCount").doc(teamCountData.id)
            .update(teamCountData)
            .then(function(docRef) {
                dispatch({type: 'ADD_TEAM_COUNT_SUCCESS'})
                toast.success('Team count succesfully updated');
            })
            .catch(error => {
                //console.log('error ', error);
            })
        }
}

export const deleteProjectTeamCount = (teamCountData) => {
    return (dispatch, getState, {
        getFirebase
    }) => {
        //console.log('teamCountData.id ',teamCountData)
        const firestore = getFirebase().firestore();
        firestore.collection("teamCount").doc(teamCountData.id)
            .delete()
            .then(function(docRef) {
                //dispatch({type: 'ADD_TEAM_COUNT_SUCCESS'})
                toast.success('Team count succesfully deleted');
            })
            .catch(error => {
                //console.log('error ', error);
            })
        }
}

export const refreshDashboard = () => {
    return {
        type: actions.REFRESH_DASHBOARD
    }
}

export const resetRefreshDashboard = () => {
    return {
        type: actions.RESET_REFRESH_DASHBOARD
    }
}

const getOrgFormattedData = (querySnapshot, month, year, orgResponseExpected) => {
    const groupedResponses = {}
    const orgData = {}
    querySnapshot.forEach(function(doc) {
        if (Object.keys(groupedResponses).length > 5) {
            return groupedResponses
        } 
        const answeredBy = doc.data().answeredBy;
        const proj = doc.data().surveyProject;

        let happyResponsesCount = doc.data().questionAnswer.filter(feedback => feedback.answer === 'happy').length
        let mehResponsesCount = doc.data().questionAnswer.filter(feedback => feedback.answer === 'meh').length
        let sadResponsesCount = doc.data().questionAnswer.filter(feedback => feedback.answer === 'sad').length
        let totalResponsesCount = doc.data().questionAnswer.length
        if (proj in groupedResponses) {
            happyResponsesCount = happyResponsesCount + groupedResponses[proj].happy
            sadResponsesCount = sadResponsesCount + groupedResponses[proj].sad
            mehResponsesCount = mehResponsesCount + groupedResponses[proj].meh
            totalResponsesCount = totalResponsesCount + groupedResponses[proj].total
        }

        groupedResponses[proj] = {happy: happyResponsesCount,sad: sadResponsesCount,meh: mehResponsesCount, total: totalResponsesCount}
        
    })
    const grpRes = []
    Object.keys(groupedResponses).forEach(key => {
        //console.log(key, groupedResponses[key]);
         grpRes.push(groupedResponses[key])
    });
    var happyTotalCount = grpRes.reduce(function (total, currentValue) {
        return total + currentValue.happy;
    }, 0);
    var sadTotalCount = grpRes.reduce(function (total, currentValue) {
        return total + currentValue.sad;
    }, 0);
    var mehTotalCount = grpRes.reduce(function (total, currentValue) {
        return total + currentValue.meh;
    }, 0);
    orgData[month+"-"+year] = {
        happy: happyTotalCount,
        sad: sadTotalCount,
        meh: mehTotalCount,
        na: orgResponseExpected - happyTotalCount - sadTotalCount - mehTotalCount
    }
  // console.log("orgData", orgData)

    return orgData
}



const sum = ( obj ) => {
    var sum = 0;
    for( var el in obj ) {
      if( obj.hasOwnProperty( el ) ) {
        sum += parseFloat( obj[el] );
      }
    }
    return sum;
}


export const getOrgFeedback = (month, year, answeredBy) => {
    return (dispatch,getState,{
        getFirebase
      }) => {
        const firestore = getFirebase().firestore();
        const activeUser = []
        const activeUserCount = []
        const surveyQuesCount = []
        const getProjects = []
        const surveys = []
        const eachProjectExpectedResponse ={}
        // -------------------------------------------
        // GET ALL PROJECTS
        // -------------------------------------------

        firestore.collection("projects").where("active","==", true)
        .get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                getProjects.push(doc.data().projectName) 
            })
            // console.log("getProjects", getProjects)
        })
        .catch(error => {
            //console.log('error ',error);
        })


        // ------------------------------------------- 
        // GET ALL PROJECT TEAM COUNT
        // -------------------------------------------

        firestore.collection("teamCount").where("year", "==", Number(year)).where("month","==",month)
        .get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                activeUser.push(doc.data())
            })
            var i;
            var tcount = 0;
            for (i = 0; i < getProjects.length; i++){
                // let userCount = activeUser.filter(d => d.projectName === getProjects[i]).length
                let getCount = activeUser.filter(d => {
                    if (d.projectName === getProjects[i]){
                    // console.log(d.count)
                    tcount = d.count
                        activeUserCount.push({project:getProjects[i] , users: tcount})
                    }
                })
                // console.log("activeUserCount", activeUserCount)
                // console.log("*******"+getProjects[i] , tcount)
            }
        })
        .catch(error => {
            //console.log('error ',error);
        })

        // ------------------------------------------- 
        // GET ALL SURVEY FOR LAST MONTH
        // -------------------------------------------

        const month_year = moment(new Date()).subtract(1, 'months').format("MMM-YYYY")

        firestore.collection("survey")
        .get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                // console.log("projectssssss", doc.data().projectName)
                surveys.push(doc.data())
            })
            var i;
            for (i = 0; i < getProjects.length; i++){
                let surveyExist = surveys.filter(d => d.surveyName === getProjects[i]+"-"+month_year).length
                let questionCount = surveys.filter(d => d.surveyName === getProjects[i]+"-"+month_year)
                    if(surveyExist){
                        surveyQuesCount.push({project:getProjects[i] , questionC: questionCount[0].question.length})
                        
                    //   console.log(getProjects[i]+"question count" , questionCount[0].question.length)
                    }
                    // else{
                    //     surveyQuesCount.push({project:getProjects[i] , questionC: 0})
                    //   // console.log(getProjects[i]+"question count" , 0)
                    // }
            }
            
        //    console.log("surveyQuesCount", surveyQuesCount)
            // console.log("--------",activeUserCount)
            Object.keys(activeUserCount).forEach(function(i){
                // eachProjectExpectedResponse[a] = activeUserCount[a]['users'] * surveyQuesCount[a]['questionC']
                Object.keys(surveyQuesCount).forEach(function(a){
                    if(activeUserCount[i].project === surveyQuesCount[a].project){
                      eachProjectExpectedResponse[i] = activeUserCount[i].users * surveyQuesCount[a].questionC
                    }
                  })
            })
           
        //    console.log("eachProjectExpectedResponse", eachProjectExpectedResponse)
        })
        .catch(error => {
            //console.log('error ',error);
        })



        // ---------------------------------------------------------- 
        // GET ALL FEEDBACK (HAPPY, SAD, MEH & NA) FOR LAST MONTH
        // ---------------------------------------------------------- 


        firestore.collection("feedback").where("year", "==", year).where("month","==",month)
            .get()
            .then(function(querySnapshot) {
                const orgResponseExpected = sum(eachProjectExpectedResponse)
                const orgFeedbackStats = getOrgFormattedData(querySnapshot, month, year, orgResponseExpected)
            //   console.log("Total expected Response=====", orgResponseExpected)
                //console.log("orgFeedbackStats", orgFeedbackStats)
                dispatch(updateOrgFeedback(orgFeedbackStats, orgResponseExpected))
               
            })
            .catch(error => {
                //console.log('error ',error);
            })
        }
    }