import React, { useState, useEffect } from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Col,
    Row,
    FormGroup,
    Input,
    Label,
} from 'reactstrap';
import { FiDelete, FiEdit2 } from "react-icons/fi";
import moment from "moment";
import { ROLE_LIST, YEAR_LIST, MONTH_LIST, CATEGORY_LIST } from "../utils/constants";
import { useSelector, useDispatch } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Select from 'react-select';
import { checkSurveyExistAndDispatch } from "../store/actions/generateSurveyAction";

const monthValue = MONTH_LIST.filter(key => key.month === moment(new Date()).format("MMM"))[0]
const yearValue = YEAR_LIST.filter(key => key == moment(new Date()).format("YYYY"))[0]

const GenerateSurvey = (props) => {
    const [modal, setModal] = useState(false)
    const [questionList, setQuestionList] = useState([])
    const [questionOptionList, setQuestionOptionList] = useState([])
    const [projectList, setProjectList] = useState([])
    const [surveyQuestionList, setSurveyQuestionList] = useState([])
    const [questionObj, setQuestionObj] = useState({})
    const [projectSelected, setProjectSelected] = useState("")
    const [roleSelected, setRoleSelected] = useState(ROLE_LIST[0])
    const [categorySelected, setCategorySelected] = useState(CATEGORY_LIST[0])
    const [monthSelected, setMonthSelected] = useState(monthValue.month)
    const [yearSelected, setYearSelected] = useState(yearValue)
    const projectName = (projectSelected === '' && projectList.length > 0) ? projectList[0].projectName : projectSelected
    const questions = useSelector(state => state.firestore.ordered.questions)
    const projects = useSelector(state => state.firestore.ordered.projects)
    const email = useSelector(state => state.auth.userData.email)
    const isSuccess = useSelector(state => state.generateSurvey.isSuccess)
    const dispatch = useDispatch();

    useEffect(() => {
     if (questions !== undefined)
            setQuestionList(questions)
        if (projects !== undefined)
            setProjectList(projects)
        if (questionOptionList.length === 0 && questionList.length > 0)
            setQuestionOptionList(questionList)
    }, [questions, projects])

    useEffect(() => {
        setModal(props.isGenerateVisible)
    }, [props.isGenerateVisible])

    useEffect(() => {
        if (isSuccess) {
            toggle()
            dispatch({ type: 'GENERATE_SURVEY_RESET'})
        }
    }, [isSuccess])

    const toggle = () => {
        resetState()
        return setModal(!modal)
    }

    const pushSurveyDataToApi = () => {
        if (surveyQuestionList.length > 0) {
            const surveyName = projectName + '-' + monthSelected + '-' + yearSelected;
            const questionList = []
            const surveyObj = {
                surveyName: surveyName,
                projectName: projectName,
                createdDate: moment(new Date()).calendar(),
                createdBy: email,
                month: monthSelected,
                year: yearSelected,
                role: roleSelected,
                question: questionList,
                isActive: true
            }
            surveyQuestionList.map(survey => {
                const questionObj = {
                    questionId: survey.id,
                    questionTitle: survey.title,
                    questionDesc: survey.description,
                    category: survey.category
                }
                questionList.push(questionObj)
            })
            // console.log(JSON.stringify(surveyObj))
            dispatch(checkSurveyExistAndDispatch(surveyName, surveyObj))
        }
    }

    const resetState = () => {
        setMonthSelected(monthValue.month)
        setYearSelected(yearValue)
        setQuestionObj({})
        setSurveyQuestionList([])
        setRoleSelected(ROLE_LIST[0])
        setCategorySelected(CATEGORY_LIST[0])
        setProjectSelected(projectName)
        props.hideModal();
    }

    const handleRemoveItem = (index) => {
        return () => {
            setSurveyQuestionList(surveyQuestionList.filter((item, i) => i !== index))
        };
    }

    const handleAddMoreBlur = (evt) => {
        if (questionObj !== undefined && questionObj.title !== undefined) {  
            const questionObject = {...questionObj}
            questionObject.category = categorySelected            
            surveyQuestionList.push(questionObject)
            setQuestionObj({})
        }
    }

    const handleQuestionInputChange = (evt) => {
        setQuestionObj(evt)
    }

    // const handleEditItem = (index) => {
    //     return () => {
    //         let it = surveyQuestionList.filter((item, i) => i === index);
    //         setQuestionObj(it)
    //     }
    // }

    const handleMonthInputChange = (evt) => {
        setMonthSelected(evt.target.value)
    }

    return (<Modal
        isOpen={modal}
        toggle={toggle}
        className={props.className}        >
        <ModalHeader toggle={toggle}><p className="mb-0">Generate survey <span className="d-block">add questions to project</span></p></ModalHeader>
        <ModalBody>
            <Row>
                <Col xl={12} lg={12} md={12}>
                    <Row>
                        <Col xl={8} lg={8} md={8}>
                            <FormGroup>
                                <Label for="projectSelect">Project</Label>
                                <Input
                                    type="select"
                                    name="Project"
                                    value={projectSelected}
                                    disabled={projectList.length === 0}
                                    onChange={e => setProjectSelected(e.currentTarget.value)} >
                                    {projectList.map(({ projectName }) => <option  key={projectName} value={projectName}>{projectName}</option>)}
                                </Input>
                            </FormGroup>
                            <Row>
                                <Col xl={6} lg={6} md={6}>
                                    <FormGroup>
                                        <Label for="monthSelect">Month</Label>
                                        <Input type="select" name="Month" value={monthSelected} onChange={e => handleMonthInputChange(e)}>
                                            {MONTH_LIST.map(({ month,i }) => <option key={month} value={month}>{month}</option>)}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col xl={6} lg={6} md={6}>
                                    <FormGroup>
                                        <Label for="yearSelect">Year</Label>
                                        <Input type="select" name="Year" value={yearSelected} onChange={e => setYearSelected(e.currentTarget.value)}>
                                            {YEAR_LIST.map((year) => <option  key={year} value={year}>{year}</option>)}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label for="fRole">Role</Label>
                        <Input
                            type="select"
                            name="Role"
                            value={roleSelected}
                            onChange={e => setRoleSelected(e.currentTarget.value)} >
                            {ROLE_LIST.map((roleName) => <option key={roleName}  value={roleName}>{roleName}</option>)}
                        </Input>
                    </FormGroup>
                    <hr />
                    <FormGroup>
                        <Label for="fQuestion">Question</Label>
                        <Select
                            id="question"
                            placeholder=""
                            options={surveyQuestionList.length === 0 ? questionList : questionList.filter(option => !surveyQuestionList.some(survey => survey.id === option.id))}
                            isSearchable={true}
                            isClearable={true}
                            value={questionObj}
                            onChange={handleQuestionInputChange}
                            getOptionLabel={question => `${question.title === undefined ? "" : question.title}`}
                        />
                    </FormGroup>            
                    <FormGroup>
                        <Label for="fCategory">Category</Label>
                        <Input
                            type="select"
                            name="Category"
                            value={categorySelected}
                            onChange={e => setCategorySelected(e.currentTarget.value)} >
                            {CATEGORY_LIST.map((catName) => <option key={catName} value={catName}>{catName}</option>)}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Button outline color="primary" size="sm" onClick={handleAddMoreBlur}>
                            ADD MORE
                        </Button>
                    </FormGroup>
                    <div className="addedQuestionBox">
                        <div className="projectNameBox px-3 py-3 d-flex justify-content-between align-items-center">
                            <h2 className="mb-0">{projectName} <br/>Survey for {roleSelected}</h2>
                            <h3 className="mb-0">{monthSelected + ' ' + yearSelected}</h3>
                        </div>
                        {surveyQuestionList.length > 0 && (<div className="questionInserted px-3">
                            <ul className="list-unstyled m-0">
                                {surveyQuestionList.map((item, i) => (
                                    <li key={i} className="py-3 d-md-flex align-items-center">
                                        <div>
                                            <div className="questionTxt">{item.title}</div>      
                                            <div className="questionTxt">{item.description}</div>                                        
                                            <div className="questionCatTxt">Category : {item.category}</div>
                                        </div>
                                        <span className="questionIcons ml-auto mr-3" onClick={handleRemoveItem(i)}>  <FiDelete /></span>
                                    </li>
                                ))}
                            </ul>
                        </div>)}
                    </div>
                </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
            <Button
                disabled={surveyQuestionList.length === 0}
                color="darkyellow"
                size="sm"
                className="mr-3"
                onClick={pushSurveyDataToApi}>
                GENERATE
            </Button>
            <Button outline color="primary" size="sm" onClick={toggle}>
                CANCEL
            </Button>
        </ModalFooter>
    </Modal>
    )
}

export default compose(
    firestoreConnect(() => [
        {
            collection: "questions",
            orderBy: ["createdOn", "desc"]
        },
        {
            collection: "projects"
        },
    ])
)(GenerateSurvey)