import * as actions from '../actions/actionTypes'
const initialState = {
    isLoading: false,
    error: null,
    completed: false,
    projects: [],
    isEditorMode: false,
    refreshProjectsFlag: false
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.GET_PROJECT_START:
            return {
                isLoading: true
            }
        case actions.GET_PROJECT_SUCCESS:
            const newprojects = [{value: '', displayValue: 'Select project', disabled: true, selected: true, manager: ''}]
            for (let project of action.projects) {
                newprojects.push({value: project.projectName, displayValue: project.projectName, manager: project.manager})
            }
            return {
                projects: [...newprojects],
                isLoading: false,
                completed: true
                
            }
        case actions.PROJECT_EDIT_START:
            return {
                isEditorMode: true,
                projects: state.projects
            }
        case actions.PROJECT_EDIT_END:
            return {
                isEditorMode: false,
                projects: state.projects
            }
        case actions.PROJECT_EDIT_SUCCESS:
            return {
                refreshProjectsFlag: true,
                isEditorMode: false,
                projects: state.projects
            }
    }
    return state
 }

 export default reducer