import React, { Component } from 'react';
import Page from '../../components/Page';
import { Container, Table, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import EditUsers from './EditUsers/EditUsers';

import { getAllUsers } from '../../store/actions/users';
import { profileEditStart, profileEditEnd, profileSubmitStart } from '../../store/actions/profile';
import AdminTabs from '../../components/Layout/AdminTabs';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
} from 'reactstrap';
import { event } from 'react-ga';
import { element } from 'prop-types';
import { AiOutlineSortAscending, AiOutlineSortDescending } from 'react-icons/ai'

class Users extends Component {

    state = {
        //editorMode: false,
        editUser: null,
        searchValue: "",
        allUsers: [],
        filteredUsers: [],
        nameSortIcon: null,
        projectSortIcon: null,
        managerSortIcon: null,
        designationSortIcon: null,
        roleSortIcon: null,
        sortType: "",
        curSortColumn: ""
    }

    componentDidMount() {
        //this.setState({ allUsers: this.state.allUsers })
        //this.props.getAllUsers()
    }

    componentDidUpdate() {
        // this.setState({ rowsList: this.state.allUsers })
    }

    componentWillReceiveProps(nextProps) {
        const flg = nextProps.editorMode ? nextProps.editorMode : false
        if (this.props.editorMode != flg) {
            //this.props.getAllUsers()
        }
        if (nextProps.users) {
            this.setState({ allUsers: nextProps.users })
            this.setState({ filteredUsers: nextProps.users })
        }
    }

    updateUserStatus = (user) => {
        let userProfile = null;
        const updatedUser = { ...user }
        updatedUser.isActive = !updatedUser.isActive
        updatedUser.fuid = user.id
        delete updatedUser.id;
        if(localStorage.getItem('storedUser')) {
            userProfile = JSON.parse(localStorage.getItem('storedUser'))
        }
        if(userProfile.email === updatedUser.email) {
            localStorage.setItem("storedUser", JSON.stringify(updatedUser))
        }
        this.props.profileUpdate(updatedUser)
    }

    showEditor = (user) => {
        let usr = { ...user }
        usr.fuid = user.id
        delete usr.id
        this.setState(prevState => {
            return {
                //editorMode: !prevState.editorMode,
                editUser: usr
            }
        })
        this.props.editorStart()
    }

    closeEditor = () => {
        this.props.editorEnd()
    }

    sortbyData = (columnName) => {
        if (this.state.curSortColumn !== columnName) {
            this.setState({
                sortType: "",
                nameSortIcon: null,
                projectSortIcon: null,
                managerSortIcon: null,
                designationSortIcon: null,
                roleSortIcon: null
            })
        }
        this.setState({ curSortColumn: columnName })
        if (this.state.sortType === "" || this.state.curSortColumn !== columnName) {
            this.sortByProjectNameAsc(columnName)
        } else if (this.state.sortType === "asec") {
            this.sortByProjectNameDesc(columnName)
        } else if (this.state.sortType === "desc") {
            this.setState({
                filteredUsers: this.state.allUsers,
                sortType: "",
                curSortColumn: "",
                nameSortIcon: null,
                projectSortIcon: null,
                managerSortIcon: null,
                designationSortIcon: null,
                roleSortIcon: null
            })
        }
    }

    sortByProjectNameAsc = (sortColumn) => {
        let sortData = [...this.state.filteredUsers].sort((a, b) => {
            return (a[sortColumn].toLowerCase().localeCompare(b[sortColumn].toLowerCase()))
        })
        this.setState({
            filteredUsers: sortData,
            sortType: "asec"
        })
        this.sortIconState("asec", sortColumn)
    }

    sortByProjectNameDesc = (sortColumn) => {
        let sortData = [...this.state.filteredUsers].sort((a, b) => {
            return (b[sortColumn].toLowerCase().localeCompare(a[sortColumn].toLowerCase()))

        })
        this.setState({
            filteredUsers: sortData,
            sortType: "desc"
        })
        this.sortIconState("desc", sortColumn)
    }

    sortIconState = (sortby, sortColumn) => {
        let sortOrder = null
        if (sortby === 'asec') {
            sortOrder = <AiOutlineSortAscending className='sort-icon' size={20} />
        } else if (sortby === 'desc') {
            sortOrder = <AiOutlineSortDescending className='sort-icon' size={20} />
        }
        this.setState({
            nameSortIcon: sortColumn === 'firstName' ? sortOrder : null,
            projectSortIcon: sortColumn === 'project' ? sortOrder : null,
            managerSortIcon: sortColumn === 'manager' ? sortOrder : null,
            designationSortIcon: sortColumn === 'designation' ? sortOrder : null,
            roleSortIcon: sortColumn === 'role' ? sortOrder : null,

        })
    }

    inputTextChange = event => {
        this.setState({
            searchValue: event.target.value
        })
        const filteredUsers = this.state.allUsers.filter(user => {
            return (
                user.firstName.toLowerCase().includes(event.target.value.toLowerCase())
                || user.lastName.toLowerCase().includes(event.target.value.toLowerCase())
                || user.empId.toLowerCase().includes(event.target.value.toLowerCase())
                || user.email.toLowerCase().includes(event.target.value.toLowerCase())
                || user.project.toLowerCase().includes(event.target.value.toLowerCase())
                || user.manager.toLowerCase().includes(event.target.value.toLowerCase())
                || user.designation.toLowerCase().includes(event.target.value.toLowerCase())
                || user.role.toLowerCase().includes(event.target.value.toLowerCase())
            )
        })
        this.setState({ filteredUsers: filteredUsers })
    }

    render() {
        let rows = []

        
        if (this.state.filteredUsers) {
            rows = this.state.filteredUsers.map((user, index) => {

                //console.log(user.firstName + ' ' + user.lastName, user.isActive, 'All Users')

                const projName = user.project
                const managers = this.state.allUsers.filter(manager => manager.id === user.manager)

                //console.log('current ', userKey, '---', this.state.allUsers[userKey].manager)
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{user.empId}</td>
                        <td>{user.firstName + ' ' + user.lastName}</td>
                        <td>{user.email}</td>
                        <td>{projName.toUpperCase()}</td>
                        <td>{user.manager && managers ? managers[0].firstName + ' ' + managers[0].lastName : 'NA'}</td>
                        <td>{user.designation}</td>
                        <td>{user.role}</td>
                        <td>
                            <Form.Check style={{ 'marginLeft': '60px' }} type="switch" key={index} id={"custom-switch " + index} label="" checked={user.isActive} onChange={() => this.updateUserStatus(user)} />
                        </td>
                        <td>
                            <div style={{ width: '100%', overflow: 'hidden' }}>
                                <Button style={{ 'float': 'left', 'marginLeft': '10px' }} variant="primary" size="sm p-1" onClick={(event) => this.showEditor(user)}>Edit</Button>
                            </div>
                        </td>
                    </tr>
                )
            })
        }


        let editModel = null
        if (this.state.filteredUsers && this.state.editUser) {
            editModel = <EditUsers show={this.props.editorMode} handleClose={this.closeEditor} user={this.state.editUser} />
        }

        return (
            <Page className="QuestionPage">
                <AdminTabs />
                <Row>
                    <Col>
                        <Card className="shadow p-3 mb-5 bg-white rounded-0 border-0">
                            <CardBody className="position-relative">
                                {editModel}
                                <input type="text" className="mr-sm-2 font-24px mt-3 mb-3 form-control bg-light border-0 small" placeholder="Search"
                                    value={this.state.searchValue}
                                    onChange={e => this.inputTextChange(e)}
                                    aria-label="Search" aria-describedby="basic-addon2" />
                                <div className="table-responsive listingTable">
                                    <Table striped hover>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Emp. Id</th>
                                                <th onClick={() => this.sortbyData("firstName")}>Name{this.state.nameSortIcon}</th>
                                                <th>Email</th>
                                                <th onClick={() => this.sortbyData("project")}>Project{this.state.projectSortIcon}</th>
                                                <th onClick={() => this.sortbyData("manager")}>Manager{this.state.managerSortIcon}</th>
                                                <th onClick={() => this.sortbyData("designation")}>Designation{this.state.designationSortIcon}</th>
                                                <th onClick={() => this.sortbyData("role")}>Role{this.state.roleSortIcon}</th>
                                                <th>Status (Active/Inactive)</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rows}
                                        </tbody>
                                    </Table>
                                    {/* <Button outline color="primary" size="sm" onClick={() => this.props.history.goBack()}>CANCEL</Button> */}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Page>
        )
    }
}

const mapStateToProps = state => {
    //console.log('state.firestore.ordered.users ', state.firestore.ordered.users)

    return {
        //users: state.firestore.data.users,
        users: state.firestore.ordered.users,
        editorMode: state.profile.isEditorMode
    }
}

const mapDispatchToProps = dispatch => {
    return {
        //getAllUsers: () => dispatch(getAllUsers()),
        editorStart: () => dispatch(profileEditStart()),
        editorEnd: () => dispatch(profileEditEnd()),
        profileUpdate: (userDetails) => dispatch(profileSubmitStart(userDetails)),
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), firestoreConnect(() => [
    {
        collection: 'users',
    },
    {
        collection: 'projects',
    }
]))(Users)