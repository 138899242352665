import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Profile from '../../profile';

const EditUsers = (props) => {
    return (
        <Modal show={props.show} onHide={props.handleClose} dialogClassName="sideModal">
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <Profile className="Modal" user={props.user} />
            </Modal.Body>
        </Modal>
    );
  }
  
  export default EditUsers;