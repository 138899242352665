const initState = {
    isSuccess: false,
    isFailure: false
}

const generateSurveyReducer = (state = initState, action) => {
    switch (action.type) {
        case 'GENERATE_SURVEY_SUCCESS':
            initState.isSuccess = true
            initState.isFailure = false
            //console.log('generate survey success');
            return state;
        case 'GENERATE_SURVEY_ERROR':
            initState.isSuccess = false
            initState.isFailure = true
            //console.log('generate survey error');
            return state;
        case 'GENERATE_SURVEY_RESET':
            initState.isSuccess = false
            initState.isFailure = false
            return state;
        default:
            return state;
    }
};

export default generateSurveyReducer;