import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import AddProjects from '../AddProjects';

const EditProjects = (props) => {
  // console.log('EditProjects ', props.project)
  return (
    <Modal show={props.show} onHide={props.handleClose} dialogClassName="sideModal">
      <Modal.Header closeButton>
      <h5 className="modal-title"><p className="mb-0">ADD PROJECT <span className="d-block">add projects in organisation</span></p></h5>
      </Modal.Header>
      <Modal.Body>
        <AddProjects className="Modal" project={props.project} takenNames={props.takenNames} />
      </Modal.Body>
    </Modal>
  );
}

export default EditProjects;