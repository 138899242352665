import * as actions from '../actions/actionTypes'
const initialState = {
    token: null,
    userData: null
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.AUTHENTICATION_SUCCESS:
            return {
                token: action.token,
                userData: action.userData
            }
        case actions.USER_PROFILE_REFRESH:
            return {
                token: state.token,
                userData: action.userData
            }
        case actions.USER_LOGOUT:
            return {
                token: null,
                userData: null
            }
        default:
            return state
    }
 }

 export default reducer