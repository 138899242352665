
import { toast } from "react-toastify";
import * as actions from './actionTypes';
import emailjs from 'emailjs-com';

const updateSurveySuccess = () => {
    return {
        type: actions.SURVEY_EDIT_SUCCESS
    }
}

export const updateSurveyAction = (surveyObj) => {
    return (dispatch, getState, { getFirebase }) => {
        let surveyId = surveyObj.id
        delete surveyObj.id
        const firestore = getFirebase().firestore();
        firestore.collection("survey").doc(surveyId)
            .update(surveyObj)
            .then(res => {
                //console.log(res);
                // dispatch(updateSurveySuccess())
                // toast.success('Project successfully updated');
            })
            .catch(error => {
                console.log(error);
                // toast.error(error);
            })
    }
}

const sendEmails = (survey, emails, getFirebase) => {
    var templateParams = {
        to_email: emails.join(','),
        from_name: 'Khushi Admin',
        project_name: survey.projectName,
        survey_period: survey.month + '/' + survey.year
      };
      
      emailjs.send('service_4vc0txb', 'template_e5mjtmg', templateParams, 'user_KfXzV9dVIuSzqe89UBSju')
      .then((result) => {
        //console.log(result.text);
        markNotifiedSurveyUsers(survey, getFirebase)
        toast.success('Email notifications sent successfully!');
      }, (error) => {
        console.log(error.text);
      });
}

export const notifySurveyUsers = (survey) => {
    return (dispatch, state, {getFirebase}) => {

        const firestore = getFirebase().firestore();
        //firestore.collection("users").where("project", "==", survey.projectName)
        firestore.collection("users").where("isActive", '==', true).where("project", "==", survey.projectName)
        .get()
        .then(function (querySnapshot) {
            let userEmails = []
            querySnapshot.forEach(function (doc) {
                userEmails.push(doc.data().email)
            })

            sendEmails(survey, userEmails, getFirebase)
        })
        .catch(error => {
            console.log(error);
        })
    }
}

const markNotifiedSurveyUsers = (surveyParam, getFirebase) => {
    const survey = {...surveyParam}
    survey.notified = true
    const firestore = getFirebase().firestore();

    firestore.collection("survey").doc(survey.id)
    .update(survey)
    .then(function (docRef) {
        console.log('marked notified')
    })
    .catch(error => {
        console.log(error);
    })
}