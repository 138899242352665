import * as actions from './actionTypes';
import axios from 'axios';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

const profileSubmitSuccess = (userData, isLoggedInUser) => {
    if (isLoggedInUser && userData) {
        localStorage.setItem("storedUser", JSON.stringify(userData))
    }
    
    return {
        type: actions.PROFILE_SUBMIT_SUCCESS
    }
}

const profileSubmitFailure = (error) => {
    return {
        type: actions.PROFILE_SUBMIT_FAILED,
        error: error
    }
}

const profileUpdate = (userData) => {
    // console.log(userData.project)
    return {
        type: actions.USER_PROFILE_REFRESH,
        userData: userData
    }
}

const profileSubmitStartLoading = () => {
    return {
        type: actions.PROFILE_SUBMIT_START
    }
}

export const profileEditStart = () => {
    return {
        type: actions.PROFILE_EDIT_START
    }
}

export const profileEditEnd = () => {
    return {
        type: actions.PROFILE_EDIT_END
    }
}

const profileCreate = (userData) => {
    
    
}

export const profileSet = () => {
    return {
        type: actions.PROFILE_ALREADY_SET
    }
}



export const profileSubmitStart = (userData, loggedInfuid) => {
    // console.log('userData ', userData)
    return (dispatch,getState,{
        getFirebase
      }) => {
        const firestore = getFirebase().firestore();
        dispatch(profileSubmitStartLoading())
        if (userData.fuid) {
            //const url = 'https://khushiapp-dev.firebaseio.com/users/' + userData.fuid + '.json'
            firestore.collection("users").doc(userData.fuid)
            .update(userData)
            .then(res => { 
                //let user = null
                dispatch(profileSubmitSuccess(userData, (userData.fuid === loggedInfuid)))
                if (userData.fuid === loggedInfuid) {
                    dispatch(profileUpdate(userData))
                }
                // console.log("a");
                toast.success('Profile successfully updated');
                // setTimeout(() => {
                //     window.location.reload();
                // }, 2000);
            })
            .catch(error => {
                console.log(error);
                dispatch(profileSubmitFailure(error))
                toast.error(error);
            })
        }
        else {
            delete userData.fuid
            firestore.collection("users")
            .add(userData)
            .then(function(docRef) {
                userData.fuid = docRef.id
                dispatch(profileUpdate(userData))
                dispatch(profileSubmitSuccess(userData, true))
                console.log("b");
                toast.success('Profile successfully updated');
                
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })
            .catch(error => {
                console.log(error);
                dispatch(profileSubmitFailure(error))
                toast.error(error);
            })
            //axios.post('https://khushiapp-dev.firebaseio.com/users.json', userData)
            
        }
        
    }

}