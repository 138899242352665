import React, { useState, useEffect, useRef } from 'react';
import firebase from './../../config/firebase';
// import { format } from 'date-fns';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { Alert } from 'reactstrap';
import ProjectScore from './ProjectScore';

const DisplayReport = props => {
  const componentRef = useRef();
  // console.log(moment().format('DDMMYYYY-hhmm-a'));

  const date = Date().split(' ');
  // we use a date string to generate our filename.
  const dateStr = moment().format('DDMMYYYY');
  // ticket title. and margin-top + margin-left
  const [noData, setNoData] = useState('');
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const [projectName, setProjectName] = useState([]);
  const [month, setMonth] = useState([]);
  const [year, setYear] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [teamCount, setTeamCount] = useState(0);
  const [feedbacksLength, setFeedbacksLength] = useState(0);
  const [questionsLength, setQuestionsLength] = useState(0);
  const properties = { header: 'Acme' };
  const [surveycomments, setSurveycomments] = useState([]);

  const collectionFeedback = firebase.firestore().collection('feedback');
  const collectionQuestion = firebase.firestore().collection('survey');

  // console.log(props.year +"===="+ props.month +"===="+ props.project)

  //ONE TIME GET FUNCTION
  function getFeedback() {
    setLoading(true);
    // collectionFeedback.where('year','==', props.year).where('month', '==', props.month).where('surveyProject', '==', props.project).get().then((item) => {
    collectionFeedback
      .where(
        'surveyName',
        '==',
        props.project + '-' + props.month + '-' + props.year,
      )
      .get()
      .then(item => {
        const items = item.docs.map(doc => {
          setFeedbacksLength(item.docs.length);
          // console.log(item.docs.length)
          // console.log(doc.data())
          return doc.data();
        });
        // console.log('feedbackssss' + items.length);
        setFeedbacks(items);
        // console.log(item.length);
        if (items.length > 0) {
          document.title = `${props.project}_khushi_${dateStr}.pdf`;
        } else {
          document.title = 'Khushi : Pulse check app';
        }
        setLoading(false);
      });
  }

  function getQuestions() {
    collectionQuestion
      .where(
        'surveyName',
        '==',
        props.project + '-' + props.month + '-' + props.year,
      )
      .get()
      .then(qitem => {
        // console.log(qitem);
        const qitems = qitem.docs.map(doc => {
          // console.log("======" + doc.data().question.length)
          setQuestionsLength(doc.data().question.length);
          return doc.data();
        });
        // console.log('questionsssss' + qitems.length);
        setQuestions(qitems);
      });
  }

  function getSurveyComments() {
    setLoading(true);
    // collectionFeedback.where('year','==', props.year).where('month', '==', props.month).where('surveyProject', '==', props.project).get().then((item) => {
    collectionFeedback
      .where(
        'surveyName',
        '==',
        props.project + '-' + props.month + '-' + props.year,
      )
      .get()
      .then(item => {
        const items = item.docs.map(doc => {
          return doc.data();
        });
        // console.log('feedbackssss' + items.length);
        setSurveycomments(items);
        console.log(items.length);

      });
  }

  useEffect(() => {
    // getFeedback();
  }, []);

  const submit = e => {
    e.preventDefault();
    // console.log(props.project);
    // console.log(props.project + '-' + props.month + '-' + props.year);
    setProjectName(props.project)
    setMonth(props.month)
    setYear(props.year)
    setTeamCount(0);
    setQuestionsLength(0);
    setFeedbacksLength(0);
    getQuestions();
    getFeedback();
    getSurveyComments();
    setShow(true);
    setNoData("No data available")
  };

  return (
    <div>
      <div className="d-flex mb-3">
        <input
          type="button"
          onClick={submit}
          className="btn btn-primary mr-3"
          value="Generate"
        />
        {show && questionsLength != 0 && feedbacksLength != 0 ? (
          <ReactToPrint
            trigger={() => (
              <button className="btn btn-outline">Save PDF</button>
            )}
            content={() => componentRef.current}
          />
        ) : ('')}
      </div>
      <div className="d-none">
        <hr />F {feedbacksLength} and Q {questionsLength}
        <hr />
      </div>

      {props.project == '' || props.month == '' ? (
        <Alert color="warning">Please select project and month</Alert>
      ) : show && questionsLength != 0 && feedbacksLength != 0 ? (
        <div className="print-report" ref={componentRef}>
          <section className="coverpage">
            <table className="table">
              <tbody>
                <tr>
                  <td colSpan="2"></td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <ProjectScore
                      project={projectName}
                      month={month}
                      year={year}
                      questionCount={questionsLength}
                      teamSize={teamCount}
                      totalRespondents={feedbacksLength}
                    />
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="2">
                    <div>Confidential</div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </section>
          <section className="otherpages">
            <table className="table">
              {/* <thead>
                <tr>
                  <td colSpan="2">
                    <div>
                      <h2>Happiness Quotient Report ({props.project})</h2>
                    </div>
                  </td>
                </tr>
              </thead> */}
              <tbody>
                {questions &&
                  questions.map((ques, index) => {
                    return (
                      <tr key={index}>
                        <td colSpan="2">
                          {ques.question.map((qu, i) => {
                            // console.log(qu.questionTitle);
                            const questionset = qu.questionTitle;
                            return (
                              <div key={i} className="question mt-5">
                                <h4 className="font-weight-bold">
                                  {qu.questionTitle}
                                </h4>
                                <hr />
                                <div className="user-answers">
                                  {feedbacks &&
                                    feedbacks.map((feedback, index) => {
                                      return (
                                        <React.Fragment key={index}>
                                          {feedback.questionAnswer.map(
                                            (q, i) => {
                                              return (
                                                <React.Fragment key={i}>
                                                  {questionset ===
                                                    q.question ? (
                                                    <div className={`list ` + q.answer}>
                                                      {/* <div>{q.question}</div> */}
                                                      {/* <div>{q.answer}</div> */}
                                                      <div className="user-comments">
                                                        {q.comments}
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    ''
                                                  )}
                                                </React.Fragment>
                                              );
                                            },
                                          )}
                                        </React.Fragment>
                                      );
                                    })}
                                </div>
                              </div>
                            );
                          })}
                        </td>
                      </tr>
                    );
                  })}
                <tr>
                  <td colSpan="2">
                    <div className="question mt-5">
                      <h4 className="font-weight-bold">
                        Additional Comments
                      </h4>
                      <hr />
                      <div className="user-answers">
                        {surveycomments &&
                          surveycomments.map((surveycomment, index) => {
                            return (
                              <React.Fragment key={index}>
                                {surveycomment.surveyComment && surveycomment.surveyComment !== "" ? (
                                  <div className="list gcyes">
                                    <div className="user-comments">
                                      {surveycomment.surveyComment}
                                    </div>
                                  </div>
                                ) : ''}
                              </React.Fragment>
                            );
                          })}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>
      ) : (<Alert color="info">{noData}</Alert>)}
    </div>
  );
};

export default DisplayReport;
