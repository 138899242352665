import { STATE_LOGIN, STATE_SIGNUP } from 'components/AuthForm';
import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';
import ProfilePage from './pages/profile';
import QuestionList from './pages/questions/QuestionList';
import Users from './pages/Users/Users';
import Projects from './pages/Projects/Projects';
import ProjectsTeamCount from './pages/ProjectsTeamCount/ProjectsTeamCount';
import { authFormShow } from './store/actions/auth';
import { connect } from 'react-redux';
import SurveyList from './pages/Surveys/SurveyList';
import GenerateReport from './pages/Reports/GenerateReport';
import Logout from './pages/Logout';
import EmailPassword from './pages/EmailPassword/EmailPassword';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const DashboardPage = React.lazy(() => import('pages/DashboardPage'));
const ListOfComponent = React.lazy(() => import('pages/ListOfComponent'));

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

class App extends React.Component {

  componentDidMount() {
    if (localStorage.getItem("storedToken") && localStorage.getItem("storedUser")) {
      this.props.startAuthLocally(localStorage.getItem("storedToken"), JSON.parse(localStorage.getItem("storedUser")))
    }
    
  }
  
  render() {
    
    let routes = (
      <Switch>
        {
          this.props.userData && this.props.userData.uid && !this.props.userData.fuid ? 
            <Redirect from='/login' to='/profile' /> : <Redirect from='/login' to='/' />
        }
        {
          this.props.userData && this.props.userData.uid && !this.props.userData.fuid ? 
          <Redirect from='/emailPassword' to='/profile' /> : <Redirect from='/emailPassword' to='/' />
        }
            
            <MainLayout breakpoint={this.props.breakpoint}>
              <React.Suspense fallback={<PageSpinner />}>
                <Route exact path="/" component={DashboardPage} />
                <Route exact path="/list-of-component" component={ListOfComponent} />
                <Route exact path="/profile" component={ProfilePage} />
                <Route exact path="/settings/users" component={Users} />
                <Route exact path="/settings/projects" component={Projects} />
                <Route exact path="/settings/projectsTeamCount" component={ProjectsTeamCount} />
                <Route exact path="/settings/question" component={QuestionList} />
                <Route exact path="/settings/survey" component={SurveyList} />
                <Route exact path="/settings/reports" component={GenerateReport} />
                <Route exact path="/settings/logout" component={Logout} />
                <Route exact path="/emailPassword" component={EmailPassword} />
              </React.Suspense>
            </MainLayout>
      </Switch>
    )

    if (!this.props.token) {
      routes = (
        <Switch>
          <LayoutRoute
              exact
              path="/login"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} authState={STATE_LOGIN} />
              )}
            />
          <Route exact path="/emailPassword" component={EmailPassword} />
          <Redirect from='/' to='/login' />
        </Switch>
      )
    }

    return (
      <>
      <BrowserRouter>
        <GAListener>
          {routes}
        </GAListener>
      </BrowserRouter>
      <ToastContainer  position="top-center"/>
      </>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    userData: state.auth.userData
  }
}

const mapDispatchToProps = dispatch => {
  return {
      startAuthLocally: (token, userData) => dispatch(authFormShow(token, userData))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(componentQueries(query)(App));
