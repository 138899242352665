import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'
import questionReducer from './questionReducer'
import generateSurveyReducer from './generateSurveyReducer'
import feedbackReducer from './feedbackReducer'
import dashboardPageReducer from './dashboardPageReducer'

import authReducer from './auth';
import profileReducer from './profile';
import usersReducer from './users';
import projectsReducer from './projects';
import survey from './survey'

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  question: questionReducer,
  generateSurvey: generateSurveyReducer,
  survey: survey,
  auth: authReducer,
  profile: profileReducer,
  users: usersReducer,
  projects: projectsReducer,
  feedback: feedbackReducer,
  dashboardPage: dashboardPageReducer
});

export default rootReducer

// the key name will be the data property on the state object