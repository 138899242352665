import * as actions from '../actions/actionTypes'
const initialState = {
    survey: []
}
const survey = (state = initialState, action) => {
    switch (action.type) {
        case actions.SURVEY_EDIT_SUCCESS:
            return {
                // isEditorMode: true,
                surveys: state.survey
            }
        
    }
    return state
 }

export default survey;