import React, { useState, useEffect, Fragment } from 'react';
import firebase from './../../config/firebase';
import { Card, CardBody, Col, Row, FormText } from 'reactstrap';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import { FiEdit2 } from 'react-icons/fi';
import moment from 'moment';
import { Bar, Doughnut } from 'react-chartjs-2';
import logo from 'assets/images/print-logo.png';

const ProjectScore = props => {
  const [loading, setLoading] = useState(false);
  const [feedbacks, setFeedbacks] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [totalRes, setTotalRes] = useState(0);
  const [nonRespondents, setNonRespondents] = useState(0);
  const collectionFeedback = firebase.firestore().collection('feedback');
  const collectionTeamCount = firebase.firestore().collection('teamCount');

  const perc = (obj, total) => {
    var perc = 0;
    perc = ((obj * 100) / total).toFixed(2);
    return perc;
  };

  function fetchData() {
    const [teamCount, setTeamCount] = useState(0);
    const [happyRes, setHappyRes] = useState(0);
    const [sadRes, setSadRes] = useState(0);
    const [mehRes, setMehRes] = useState(0);
    useEffect(() => {
      // console.log(typeof(props.year))
      collectionTeamCount
        .where('year', '==', props.year)
        .where('month', '==', props.month)
        .where('projectName', '==', props.project)
        .get()
        .then(tcount => {
          // console.log(tcount);
          // console.log(props.year+"---"+props.month+"---"+props.project)
          const tcounts = tcount.docs.map(doc => {
            // console.log(doc.data().count);
            return doc.data().count;
          });
          // console.log('tcounts======' + tcounts);
          // setTeamCount(tcounts);
          setTeamCount(tcounts);
        });
    }, []);

    let allscore = {};
    let happyCount,
      sadCount,
      mehCount,
      nr = 0;

    useEffect(() => {
      collectionFeedback
        .where(
          'surveyName',
          '==',
          props.project + '-' + props.month + '-' + props.year,
        )
        .get()
        .then(item => {
          const items = item.docs.map(doc => {
            happyCount = doc
              .data()
              .questionAnswer.filter(feedback => feedback.answer === 'happy')
              .length;
            mehCount = doc
              .data()
              .questionAnswer.filter(feedback => feedback.answer === 'meh')
              .length;
            sadCount = doc
              .data()
              .questionAnswer.filter(feedback => feedback.answer === 'sad')
              .length;
            // totalCount = doc.data().questionAnswer.length;

            if (allscore.happy != undefined) {
              happyCount = +allscore.happy + happyCount;
            }
            if (allscore.sad != undefined) {
              sadCount = +allscore.sad + sadCount;
            }
            if (allscore.meh != undefined) {
              mehCount = +allscore.meh + mehCount;
            }

            // ////console.log(happyCount + "====" + mehCount+ "====" +sadCount + "====" + totalCount)
            allscore = {
              happy: happyCount,
              sad: sadCount,
              meh: mehCount,
              nr:
                teamCount * props.questionCount -
                happyCount -
                mehCount -
                sadCount,
            };
            // doc.data()
          });
          // setTotalRes(allscore.nr)

          //console.log('allscore', allscore);

          setHappyRes(allscore.happy);
          setMehRes(allscore.meh);
          setSadRes(allscore.sad);

          // return allscore;
        });
    }, []);

    return [teamCount, happyRes, mehRes, sadRes];
  }

  function getKhushiScore() {
    let projkhushiscore = 0;
    let overallRes = happyRes * 10 + mehRes * 5;
    let maxPoints = props.questionCount * teamCount * 10;
    projkhushiscore = overallRes / maxPoints;
    ////console.log(projkhushiscore);
    return projkhushiscore;
  }
  const [teamCount, happyRes, mehRes, sadRes] = fetchData();
  const projkhushiscore = getKhushiScore();

  const data = {
    datasets: [
      {
        data: [
          perc(happyRes, props.questionCount * teamCount),
          perc(mehRes, props.questionCount * teamCount),
          perc(sadRes, props.questionCount * teamCount),
          perc(
            teamCount * props.questionCount - happyRes - mehRes - sadRes,
            props.questionCount * teamCount,
          ),
        ],
        backgroundColor: ['#68CD78', '#F8D580', '#DD896A', '#CCCCCC'],
        hoverBackgroundColor: ['#68CD78', '#F8D580', '#DD896A', '#CCCCCC'],
      },
    ],
    labels: [
      'Happy ' + perc(happyRes, props.questionCount * teamCount) + '%',
      'Meh ' + perc(mehRes, props.questionCount * teamCount) + '%',
      'Sad ' + perc(sadRes, props.questionCount * teamCount) + '%',
      'Non respondent ' +
        perc(
          teamCount * props.questionCount - happyRes - mehRes - sadRes,
          props.questionCount * teamCount,
        ) +
        '%',
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
  };
  return (
    <>
      <div className="print-header primary-bg p-3 text-center">
        <div className="d-none d-print-block">
          <img src={logo} width="500" />
          <hr />
          <h2 className="font-weight-bold">Happiness Quotient Report</h2>
          <hr />
        </div>
        <div className="d-flex font-weight-bold justify-content-between metadata-project px-4">
          <div className="col-3">
            <small>Project</small>
            <br />
            {props.project}
          </div>
          <div className="col-3">
            <small>Month-Year</small>
            <br />
            {props.month} {props.year}
          </div>
          <div className="col-3">
            <small>Team size</small>
            <br />
            {teamCount}
          </div>
          <div className="col-3">
            <small>Total respondents</small>
            <br />
            {props.totalRespondents}
          </div>
        </div>
      </div>
      {/* Project score : {props.project} {props.month} {props.year}{' '}
        {props.questionCount} {teamCount} ==== {teamCount} ====
        {happyRes} - {sadRes} - {mehRes} */}
      <div className="blue-bg text-center p-3">
        <div className="d-flex font-weight-bold justify-content-between metadata-project px-4">
          <div className="col-3">
            <small>Project Khushi score</small>
            <br />
            <h2 className="font-weight-bold">
              {projkhushiscore != Infinity
                ? Number((projkhushiscore * 100).toFixed(0)) + '%'
                : '0%'}
            </h2>
          </div>
          <div className="col-3">
            <small>Happy</small>
            <br />
            <h2 className="font-weight-bold">{happyRes}</h2>
          </div>
          <div className="col-3">
            <small>Sad</small>
            <h2 className="font-weight-bold">{sadRes}</h2>
          </div>
          <div className="col-3">
            <small>Meh</small>
            <br />
            <h2 className="font-weight-bold">{mehRes}</h2>
          </div>
        </div>
      </div>
      <div className="p-3">
        <FormText color="dark">
          % = Total Happy Responses X 10 + Total Meh Responses X 5, where Happy
          -10 points &amp; Meh - 5 points{' '}
        </FormText>
      </div>
      <div className="chart-content mt-5">
        <div className="col mx-auto">
          <h4 className="font-weight-bold text-center text-uppercase">
            Project chart
          </h4>
          <div className="chart-container">
            <Doughnut data={data} options={options} />
          </div>
        </div>
        {/* <div className="col d-none">
          <h4 className="font-weight-bold text-center text-uppercase">
            Organisation chart
          </h4>
          <div className="chart-container">
            <Doughnut data={data} options={options} />
          </div>
        </div> */}
      </div>
    </>
  );
};

export default ProjectScore;
