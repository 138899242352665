export const AUTHENTICATION_START = 'AUTHENTICATION_START';
export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED';
export const USER_PROFILE_REFRESH = 'USER_PROFILE_REFRESH';
export const USER_LOGOUT = 'USER_LOGOUT';

export const PROFILE_SUBMIT_START = 'PROFILE_SUBMIT_START';
export const PROFILE_SUBMIT_SUCCESS = 'PROFILE_SUBMIT_SUCCESS';
export const PROFILE_SUBMIT_FAILED = 'PROFILE_SUBMIT_FAILED';
export const PROFILE_EDIT_START = 'PROFILE_EDIT_START';
export const PROFILE_EDIT_END = 'PROFILE_EDIT_END';
export const PROFILE_ALREADY_SET = 'PROFILE_ALREADY_SET';



export const GET_PROJECT_START = 'GET_PROJECT_START';
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
export const GET_PROJECT_FAILED = 'GET_PROJECT_FAILED';

export const PROJECT_EDIT_START = 'PROJECT_EDIT_START';
export const PROJECT_EDIT_END = 'PROJECT_EDIT_END';
export const PROJECT_EDIT_SUCCESS = 'PROJECT_EDIT_SUCCESS';

export const PROJECT_GET_MANAGERS = 'PROJECT_GET_MANAGERS';
export const PROJECT_SUBMIT_START = 'PROJECT_SUBMIT_START';
export const PROJECT_SUBMIT_SUCCESS = 'PROJECT_SUBMIT_SUCCESS';
export const PROJECT_SUBMIT_FAILED = 'PROJECT_SUBMIT_FAILED';

export const GET_USERS_START = 'GET_USERS_START';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';

export const GET_USER_FEEDBACK = 'GET_USER_FEEDBACK';
export const GET_PROJECT_FEEDBACK = 'GET_PROJECT_FEEDBACK';
export const GET_PROJECT_TEAM_COUNT = 'GET_PROJECT_TEAM_COUNT';
export const ADD_TEAM_COUNT_SUCCESS = 'ADD_TEAM_COUNT_SUCCESS';
export const CREATE_COUNT_RESET = 'CREATE_COUNT_RESET';
export const GET_ORG_FEEDBACK = 'GET_ORG_FEEDBACK';
export const REFRESH_DASHBOARD = 'REFRESH_DASHBOARD';
export const RESET_REFRESH_DASHBOARD = 'RESET_REFRESH_DASHBOARD';

export const SURVEY_EDIT_SUCCESS = 'SURVEY_EDIT_SUCCESS';
