import logo from 'assets/images/login-logo.png';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form } from 'reactstrap';

class AuthForm extends React.Component {
  get isLogin() {
    return this.props.authState === STATE_LOGIN;
  }

  get isSignup() {
    return this.props.authState === STATE_SIGNUP;
  }

  changeAuthState = authState => event => {
    event.preventDefault();

    this.props.onChangeAuthState(authState);
  };

  /*handleSubmit = event => {
    event.preventDefault();
  };*/


  render() {
    const {
      showLogo,
    } = this.props;

    return (
      <Form onSubmit={this.props.handleSubmit}>
        {showLogo && (
          <div className="text-center loginLogo">
            <img
              src={logo}
              alt="logo"
            />
          </div>
        )}
        <div className="loginBtn text-center mt-3">
          <Button
            size="md"
            className="py-3 btn btn-primary text-uppercase mb-4"
            block
            onClick={this.props.handleSubmit}>
            Login with office 365
          </Button>
          {/* <h1 className="">OR</h1> */}
          <Button
            size="md"
            className="py-3 text-uppercase"
            color="link"
            block
            onClick={this.props.emailLogin}>
            Admin Login
          </Button>
        </div>
      </Form>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Email',
  usernameInputProps: {
    type: 'email',
    placeholder: 'your@email.com',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => { },
};

export default AuthForm;
