
import { toast } from "react-toastify";
import { notifySurveyUsers } from './survey';

export const checkSurveyExistAndDispatch = (surveyName, surveyList) => {
    return (dispatch, getState, { getFirebase }) => {
        const firestore = getFirebase().firestore();
        firestore.collection("survey").where("surveyName", "==", surveyName).get()
            .then(function (querySnapshot) {
                let surveyFlag = querySnapshot.docs.length > 0
                if (surveyFlag) {
                    // alert('Survey already generated')
                    toast.error('It looks like you have already generated survey for selected project and month.');
                } else {
                    dispatch(generateSurveyAction(surveyList))
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
}

export const generateSurveyAction = (surveyList) => {
    return (dispatch, getState, { getFirebase }) => {
        const firestore = getFirebase().firestore();
        firestore
            .collection("survey")
            // .doc(surveyName)
            .add({
                ...surveyList,
                createdOn: new Date()
            })
            .then((docRef) => {
                const survey = {...surveyList}
                survey.id = docRef.id
                dispatch({
                    type: 'GENERATE_SURVEY_SUCCESS'
                });
                dispatch(notifySurveyUsers(survey))
                toast.success('Survey successfully generated');
            })
            .catch((err) => {
                dispatch({
                    type: 'GENERATE_SURVEY_ERROR',
                    err
                });
                toast.error(err);
            });
    }
}