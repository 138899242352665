import React, { useState, useEffect, useRef  } from 'react';
import logo from 'assets/images/login-logo.png';
import firebase from "firebase/app"
import { connect } from 'react-redux';
import { emailAuthStart } from '../../store/actions/auth';
import { useForm } from 'react-hook-form';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Card, Col, Row } from 'reactstrap';
import Page from 'components/Page';
import { toast } from "react-toastify";
// import NET from 'vanta/dist/vanta.net.min'

const EmailPassword = (props) => {
    // const [vantaEffect, setVantaEffect] = useState(0)
    // const vantaRef = useRef(null)
    // useEffect(() => {
    //   if (!vantaEffect) {
    //     setVantaEffect(NET({
    //       el: vantaRef.current,
    //       mouseControls: true,
    //       touchControls: true,
    //       gyroControls: false,
    //       minHeight: 200.00,
    //       minWidth: 200.00,
    //       scale: 1.00,
    //       scaleMobile: 1.00,
    //       color: 0xf7f0ff,
    //       backgroundColor: 0x552681,
    //       points: 12.00
    //     }))
    //   }
    //   return () => {
    //     if (vantaEffect) vantaEffect.destroy()
    //   }
    // }, [vantaEffect])
    const [userName, setUserName] = useState()
    const [password, setPassword] = useState()
    const signup = () => {
        firebase.auth().createUserWithEmailAndPassword(userName, password)
            .then(res => {
                if (res.additionalUserInfo.isNewUser) {
                    toast.success("Account created successfully!");
                }
            })
            .catch(function (error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // ...

                toast.error(errorMessage);
                // alert(error.message)
            });
    }
    const { register, handleSubmit, watch, errors } = useForm();

    const signin = () => {
        firebase.auth().signInWithEmailAndPassword(userName, password)
            .then(result => {

                require('crypto').randomBytes(48, function (err, buffer) {
                    const token = buffer.toString('hex');
                    if (!result.additionalUserInfo.isNewUser) {
                        //console.log('res ', result.user.email)
                        const name = result.user.email.split('@')[0]

                        const userData = {
                            uid: result.user.uid,
                            email: result.user.email,
                            firstName: name.split('.')[0],
                            lastName: name.split('.')[1]
                        }

                        props.authStart(token, userData);
                        toast.success('success!');
                    }
                    else {
                        toast.error('No such user found!');
                    }
                });


            })
            .catch(function (error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // ...
                toast.error(errorMessage);
            });

    }

    return (

        <Page
            className="LoginPage"
        >
            <Row className="loginContent justify-content-center align-items-center">
                <Col className="loginBox mb-0">
                    <Card body className="justify-content-center">

                        <div className="text-center loginLogo">
                            <img
                                src={logo}
                                alt="logo"
                            />
                        </div>
                        <div className="login-form">
                            <div className="form-group">
                                {/* <label htmlFor="email" className="">Email</label> */}
                                <input type="text" id="email" className="form-control" placeholder="Email" value={userName} onChange={event => setUserName(event.target.value)} />
                            </div>
                            <div className="form-group">
                                {/* <label htmlFor="password" className="">Password</label> */}
                                <input type="password" id="password" placeholder="Password" value={password} onChange={event => setPassword(event.target.value)} className="form-control" />
                            </div>
                            <div className="d-flex justify-content-start">
                                <button onClick={signin} className="btn btn-primary mr-3">Sign in</button>
                                <button onClick={signup} className="btn btn-secondary">Signup</button>
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
            
            {/* <div className="bg-animation" ref={vantaRef}>
            &nbsp;
            </div> */}
        </Page >
    )
}

const mapDispatchToProps = dispatch => {
    return {
        authStart: (token, userData) => dispatch(emailAuthStart(token, userData))
    }
}

export default connect(null, mapDispatchToProps)(EmailPassword)