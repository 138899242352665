import React, { Component } from 'react'
import Page from '../../components/Page';
import { Container, Table, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

import EditProjects from './EditProjects/EditProjects';
import { getAllProjects } from '../../store/actions/projects';
import { projectEditStart, projectEditEnd, projectAddStart } from '../../store/actions/projects';
import { profileGetManager } from '../../store/actions/users';
import AdminTabs from '../../components/Layout/AdminTabs';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
} from 'reactstrap';
import { AiOutlineSortAscending, AiOutlineSortDescending } from 'react-icons/ai'

class Projects extends Component {
    state = {
        editProject: null,
        searchValue: "",
        allProjects: [],
        filteredProjects: [],
        sortType: "",
        projectSortIcon: null
    }

    componentDidMount() {
        //this.props.getAllProjects()
        this.props.getManagers()
    }

    componentDidUpdate() {
        // if (this.props.refreshProjectsFlag) {
        //this.props.getAllProjects()
        // }
    }

    componentWillReceiveProps(nextProps) {
        const flg = nextProps.editorMode ? nextProps.editorMode : false
        if (this.props.editorMode != flg) {
            //this.props.getAllUsers()
        }
        if (nextProps.projects) {
            this.setState({ allProjects: nextProps.projects })
            this.setState({ filteredProjects: nextProps.projects })
        }
    }

    updateUserStatus = (selectedProj, index) => {
        let project = { ...selectedProj }
        project.active = !project.active
        this.props.projectUpdate(project)
    }

    showEditor = (project, index) => {
        this.setState(prevState => {
            return {
                editProject: index >= 0 ? project : null
            }
        })

        this.props.editorStart()
    }

    closeEditor = () => {
        this.props.editorEnd()
    }

    sortbyData = () => {
        if (this.state.sortType === "") {
            this.sortByProjectNameAsc()
        } else if (this.state.sortType === "asec") {
            this.sortByProjectNameDesc()
        } else if (this.state.sortType === "desc") {
            this.setState({
                filteredUsers: this.state.allProjects,
                sortType: "",
                projectSortIcon: null
            })
        }
    }

    sortByProjectNameAsc = () => {
        let sortData = [...this.state.filteredProjects].sort((a, b) => {
            return (a.projectName.toLowerCase().localeCompare(b.projectName.toLowerCase()))
           })
        this.setState({
            filteredProjects: sortData,
            sortType: "asec",
            projectSortIcon: <AiOutlineSortAscending size={20} />
        })
    }

    sortByProjectNameDesc = () => {
        let sortData = [...this.state.filteredProjects].sort((a, b) => {
            return (b.projectName.toLowerCase().localeCompare(a.projectName.toLowerCase()))
        })
        this.setState({
            filteredProjects: sortData,
            sortType: "desc",
            projectSortIcon: <AiOutlineSortDescending size={20} />
        })
    }

    inputTextChange = event => {
        this.setState({
            searchValue: event.target.value
        })

        const filteredProjects = this.state.allProjects.filter(project => {
            return (
                project.projectName.toLowerCase().includes(event.target.value.toLowerCase())
                || project.description.toLowerCase().includes(event.target.value.toLowerCase())
                || project.manager.toLowerCase().includes(event.target.value.toLowerCase())
            )
        })

        this.setState({ filteredProjects: filteredProjects })
    }

    render() {
        let rows = []
        if (this.state.filteredProjects) {
            rows = this.state.filteredProjects.map((project, index) => {
                const managers = this.props.managers.filter(mg => mg.value == project.manager)
                const manager = managers.length > 0 ? managers[0].displayValue : 'NA'
                return (
                    <tr key={project.projectName}>
                        <td>{project.projectName}</td>
                        <td>{project.description}</td>
                        <td>{manager}</td>
                        <td>
                            <Form.Check style={{ 'marginLeft': '60px' }} type="switch" key={index} id={"custom-switch " + index} label="" checked={project.active} onChange={() => this.updateUserStatus(project, index)} />
                        </td>
                        <td>
                            <div style={{ overflow: 'hidden' }}>
                                <Button style={{ 'float': 'left', 'marginLeft': '10px' }} variant="primary" size="sm p-1" onClick={() => this.showEditor(project, index)}>Edit</Button>
                            </div>
                        </td>
                    </tr>
                )
            })
        }

        let editModel = null
        if (this.state.filteredProjects && this.props.editorMode) {
            editModel = <EditProjects show={this.props.editorMode} handleClose={this.closeEditor} project={this.state.editProject} takenNames={this.state.filteredProjects.map(proj => proj.projectName.toLowerCase())} />
        }

        return (
            <Page className="QuestionPage">
                <AdminTabs />
                <Row>
                    <Col>
                        <Card className="shadow p-3 mb-5 bg-white rounded-0 border-0">
                            <CardBody className="position-relative">
                                {editModel}
                                <Button className="btn-darkyellow mb-3" size="sm" onClick={(event) => this.showEditor(event, -1)}>Add a project</Button>
                                <input type="text" className="mr-sm-2 font-24px mt-3 mb-3 form-control bg-light border-0 small" placeholder="Search"
                                    value={this.state.searchValue}
                                    onChange={e => this.inputTextChange(e)}
                                    aria-label="Search" aria-describedby="basic-addon2" />
                                <div className="table-responsive">
                                    <Table striped hover>
                                        <thead>
                                            <tr>
                                                <th onClick={() => this.sortbyData()}>Project Name{this.state.projectSortIcon}</th>
                                                <th>Description</th>
                                                <th>Manager</th>
                                                <th>Status (Active/Inactive)</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rows}
                                        </tbody>
                                    </Table>
                                    {/* <Button outline color="primary" size="sm" onClick={() => this.props.history.goBack()}>CANCEL</Button> */}
                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Page>
        )
    }
}

const mapStateToProps = state => {
    return {
        projects: state.firestore.ordered.projects,
        managers: state.users.managers,
        editorMode: state.projects.isEditorMode,
        refreshProjectsFlag: state.projects.refreshProjectsFlag
    }
}

const mapDispatchToProps = dispatch => {
    return {
        //getAllProjects: () => dispatch(getAllProjects()),
        getManagers: () => dispatch(profileGetManager()),
        editorStart: () => dispatch(projectEditStart()),
        editorEnd: () => dispatch(projectEditEnd()),
        projectUpdate: (projDetails) => dispatch(projectAddStart(projDetails))
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), firestoreConnect(() => [
    {
        collection: 'projects',
        orderBy: ["created_date", "desc"]
    }
]))(Projects)