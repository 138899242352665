import React, { useState, useEffect } from 'react'
import Page from '../../components/Page';
import { Link } from 'react-router-dom'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import AdminTabs from '../../components/Layout/AdminTabs';
import { Container, Table, Button, Form } from 'react-bootstrap';
import { updateSurveyAction, notifySurveyUsers } from '../../store/actions/survey';
import { useSelector, useDispatch } from 'react-redux'
import moment from "moment";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert'
import { element } from 'prop-types';
import { MdNotifications } from 'react-icons/md'
import { AiOutlineSortAscending, AiOutlineSortDescending } from 'react-icons/ai'
// import 'react-confirm-alert/src/react-confirm-alert.css'

const SurveyList = (props) => {
  // console.log(surveys);surveys:
  const [query, setQuery] = useState("")
  const [sortType, setSortType] = useState("")
  const [sortTypeIcon, setSortTypeIcon] = useState(null)
  const [surveyList, setSurveyList] = useState([])
  const [surveyFilterList, setSurveyFilterList] = useState([])
  const surveys = useSelector(state => state.firestore.ordered.survey)
  const dispatch = useDispatch();


  useEffect(() => {
    if (surveys !== undefined) {
      setSurveyList(surveys)
      setSurveyFilterList(surveys)
    }
  }, [surveys])

  useEffect(() => {
    const strQuery = query
    if (strQuery.length === 0 && surveys !== undefined) {
      setSurveyFilterList(surveyList)
    } else {
      let filterData = surveyList.filter(survey => {
        return (survey.surveyName.toLowerCase().includes(strQuery.toLowerCase())
          || survey.projectName.toLowerCase().includes(strQuery.toLowerCase())
          || survey.role.toLowerCase().includes(strQuery.toLowerCase())
          || survey.month.toLowerCase().includes(strQuery.toLowerCase())
          || survey.year.toString().includes(strQuery.toLowerCase())
        )
      })
      setSurveyFilterList(filterData)
    }
  }, [query])

  const inputTextChange = event => {
    setQuery(event.target.value)
  }

  const sortProjectName = () => {
    if (sortType === "") {
      setSortTypeIcon(<AiOutlineSortAscending className='sort-icon' size={20} />)
      sortByProjectNameAsc()
    } else if (sortType === "asec") {
      setSortTypeIcon(<AiOutlineSortDescending className='sort-icon' size={20} />)
      sortByProjectNameDesc()
    } else if (sortType === "desc") {
      setSurveyFilterList(surveyList)
      setSortType("")
      setSortTypeIcon(null)
    }
  }

  const sortByProjectNameAsc = () => {
    let sortData = [...surveyFilterList].sort((a, b) => {
      return (a.projectName.toLowerCase().localeCompare(b.projectName.toLowerCase()))
    })
    setSurveyFilterList(sortData)
    setSortType("asec")
  }

  const sortByProjectNameDesc = () => {
    let sortData = [...surveyFilterList].sort((a, b) => {
      return (b.projectName.toLowerCase().localeCompare(a.projectName.toLowerCase()))
      // return (b.projectName - a.projectName)
    })
    setSurveyFilterList(sortData)
    setSortType("desc")
  }

  const updateSurveyStatus = (survey) => {

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div id="react-confirm-alert">
            <div class="react-confirm-alert-overlay">
              <div class="react-confirm-alert-body">
                <div class="custom-ui">
                  <h1>Are you sure?</h1>
                  <p>You want to stop the survey.</p>
                  <button onClick={onClose} className="btn btn-outline mr-3">No</button>
                  <button className="btn btn-primary"
                    onClick={() => {
                      const updatedSurvey = { ...survey }
                      updatedSurvey.isActive = !updatedSurvey.isActive
                      dispatch(updateSurveyAction(updatedSurvey))
                      onClose()
                    }}> Yes </button>
                </div>
              </div>
            </div>
          </div>
        )
      }
    })
  }

  return (
    <Page className="SurveyPage">
      <AdminTabs />
      <Row>
        <Col>
          <Card className="shadow p-3 mb-5 bg-white rounded-0 border-0">
            <CardBody className="position-relative">
              <input type="text" className="mr-sm-2 font-24px mt-3 mb-3 form-control bg-light border-0 small" placeholder="Search"
                value={query}
                onChange={e => inputTextChange(e)}
                aria-label="Search" aria-describedby="basic-addon2" />
              <div className="table-responsive listingTable">
                <Table striped hover>
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col" onClick={() => sortProjectName()}>
                        Project{sortTypeIcon}
                      </th>
                      <th scope="col">Role</th>
                      <th scope="col">Month</th>
                      <th scope="col">Year</th>
                      <th scope="col">Questions</th>
                      <th scope="col">Created on</th>
                      <th>Status (Active/Inactive)</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {surveyFilterList && surveyFilterList.map((survey, i) => {
                      return (
                        <React.Fragment key={i}>
                          <Survey index={i} survey={survey} callBack={updateSurveyStatus} />
                        </React.Fragment>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  )
}

const Survey = (surveyProps) => {
  const dispatch = useDispatch();
  let survey = surveyProps.survey

  const sendEmails = (survey) => {
    var r = window.confirm('Are you sure?');
    if (r == true) {
      dispatch(notifySurveyUsers(survey))
    }
  }

  return (
    <>
      <tr key={survey.id}>
        <td>{survey.surveyName}</td>
        <td>{survey.projectName}</td>
        <td>{survey.role}</td>
        <td>{survey.month}</td>
        <td>{survey.year}</td>
        <td>
          {survey.question && survey.question.map((question, i) => {
            return (
              <React.Fragment key={i}>
                {question.questionTitle} ({question.category})<br />
              </React.Fragment>
            )
          })}
        </td>
        <td>{moment(survey.createdOn.toDate()).format('DD-MMM-YYYY')}</td>
        <td>
          <Form.Check style={{ 'marginLeft': '60px' }} type="switch" key={surveyProps.index} id={"custom-switch " + surveyProps.index} label="" checked={survey.isActive} disabled={!survey.isActive} onChange={() => surveyProps.callBack(surveyProps.survey)} />
        </td>
        <td>
          <button disabled={survey.notified ? true : false} style={{ border: 'none', background: 'none' }} onClick={() => sendEmails(survey)}><MdNotifications size={30} /></button>
        </td>
      </tr>
    </>
  )
}


export default compose(
  firestoreConnect(() => [
    {
      collection: "survey",
      orderBy: ["createdOn", "desc"]
    },
  ])
)(SurveyList)