import logo from 'assets/images/logo.png';
import sidebarBgImage from 'assets/img/sidebar/sidebar-4.jpg';
import React from 'react';
import {
  MdAccountCircle,
  MdDashboard,
  MdSettings,
  MdInsertChart,
  MdExitToApp
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';

import {
  // UncontrolledTooltip,
  Button,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from 'utils/bemnames';
//import { connect } from 'http2';
import { connect } from 'react-redux';



const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const pageContents = [
  { to: '/login', name: 'login / signup', exact: false, Icon: MdAccountCircle },
];

const navItems = [
  { to: '/', name: 'Dashboard', exact: true, Icon: MdDashboard },
  { to: '/settings/users', name: 'Settings', exact: false, Icon: MdSettings },
  { to: '/profile', name: 'Profile', exact: false, Icon: MdAccountCircle },
  { to: '/settings/logout', name: 'Exit', exact: false, Icon: MdExitToApp },
];

const managerNavItems = [
  { to: '/', name: 'Dashboard', exact: true, Icon: MdDashboard },
  { to: '/settings/reports', name: 'Settings', exact: false, Icon: MdSettings },
  { to: '/settings/logout', name: 'Exit', exact: false, Icon: MdExitToApp },
];
const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
    currentUrl: ''
  };

  handleAnchorClick = (event) => {
    let curUrl = event.currentTarget.href;
    var updateUrl = curUrl.replace(window.location.origin, "");
    //console.log(updateUrl)
    this.setState({
      currentUrl: updateUrl
    });
}

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    let filteredNavItems = [...navItems]
    if (this.props.userData.role !== 'Admin' && this.props.userData.role !== 'Manager') {
      filteredNavItems.splice(1, 1)
    }else if (this.props.userData.role == 'Admin'){
      filteredNavItems = [...navItems]
    }else if (this.props.userData.role == 'Manager'){
      filteredNavItems = [...managerNavItems]
    }

    return (
      <aside className={bem.b()}>
      <div className={bem.e('content')}>
        <Nav vertical>
          {filteredNavItems.map(({ to, name, exact, Icon }, index) => ( 
            <NavItem key={index} className={bem.e('nav-item')}>
              <BSNavLink
                id={`navItem-${name}-${index}`}
                className="text-uppercase"
                tag={NavLink}
                to={to}
                //activeClassName='active'
                activeClassName= {(this.state.currentUrl === to) ? 'active' : (to === '/') ? 'active' : ''}
                exact={exact}
                onClick={this.handleAnchorClick}
              >
                <div className='logo-with-title-div'>
                  <div className='sidebar-icon-parent'><Icon className={bem.e('nav-item-icon')} /></div>
                  <div className="menu-name">{name}</div>
                </div>
              </BSNavLink>
            </NavItem>
          ))}

          </Nav>
      </div>
    </aside>
    );
  }
}

const mapStateToProps = state => {
  return {
    userData: state.auth.userData
  }
}

export default connect(mapStateToProps)(Sidebar);
