import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux';

export class AdminTabs extends Component {
    render() {
        let adminTab = false
        if (this.props.userData.role == 'Admin') {
            adminTab = true
        }

        return (
            <div className="mt-3">
                <ul className="nav nav-tabs">
                    {
                        adminTab ?
                        (
                            <>
                                <li className="nav-item">
                                    <NavLink activeClassName="selected"  className="nav-link" to="/settings/users">Manage Users</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeClassName="selected" className="nav-link"  to="/settings/projects">Manage Projects</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeClassName="selected" className="nav-link"  to="/settings/projectsTeamCount">Manage Team Count</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeClassName="selected" className="nav-link"  to="/settings/question">Manage Questions</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeClassName="selected" className="nav-link"  to="/settings/survey">Manage Survey</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeClassName="selected" className="nav-link"  to="/settings/reports">Reports</NavLink>
                                </li>
                            </>
                        ):(
                            <>
                                <li className="nav-item">
                                    <NavLink activeClassName="selected" className="nav-link"  to="/settings/reports">Reports</NavLink>
                                </li>
                            </>
                        )
                    }
                </ul>
            </div>
        )
    }
}

const mapStateToProps = state => {
    // console.log("state.auth.userData",state.auth.userData.role)
    return {
      userData: state.auth.userData
    }
  }

export default connect(mapStateToProps)(AdminTabs);
