import React, { useState, useEffect } from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Col,
    Row,
    FormGroup,
    Input,
    Label,
} from 'reactstrap';
import { FiDelete, FiEdit2 } from "react-icons/fi";
import moment from "moment";
import { YEAR_LIST, MONTH_LIST } from "../../utils/constants";
import { useSelector, useDispatch } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Select from 'react-select';
import { addProjectTeamCount, updateProjectTeamCount } from "../../store/actions/dashboardPage";
import { toast } from "react-toastify";

const monthValue = MONTH_LIST.filter(key => key.month === moment(new Date()).format("MMM"))[0]
const yearValue = YEAR_LIST.filter(key => key == moment(new Date()).format("YYYY"))[0]

const CreateTeamCount = (props) => {
    const [modal, setModal] = useState(false)
    const [projectList, setProjectList] = useState([])
    const [projectSelected, setProjectSelected] = useState("")
    const [monthSelected, setMonthSelected] = useState(monthValue.month)
    const [yearSelected, setYearSelected] = useState(yearValue)
    const [teamCount, setTeamCount] = useState(0)
    const projectName = (projectSelected === '' && projectList.length > 0) ? projectList[0].projectName : projectSelected
    const projects = useSelector(state => state.firestore.ordered.projects)
    const fuid = useSelector(state => state.auth.userData.fuid)
    const isSuccess = useSelector(state => state.dashboardPage.isTeamCountAddedSuccess)
    const dispatch = useDispatch();

    useEffect(() => {
        if (projects !== undefined)
            setProjectList(projects)
    }, [projects])

    useEffect(() => {
        setModal(props.isGenerateVisible)
    }, [props.isGenerateVisible])

    useEffect(() => {
        //console.log('props.selectedProject ',props.selectedProject)
        setProjectSelected(props.selectedProject ? props.selectedProject.projectName : '')
        setMonthSelected(props.selectedProject ? props.selectedProject.month : '')
        setYearSelected(props.selectedProject ? props.selectedProject.year : '')
        setTeamCount(props.selectedProject ? props.selectedProject.count : '')
    }, [props.selectedProject])

    useEffect(() => {
        //console.log('isSuccess ',isSuccess)
        if (isSuccess) {
            toggle()
            dispatch({ type: 'CREATE_COUNT_RESET'})
        }
    }, [isSuccess])

    const toggle = () => {
        resetState()
        return setModal(!modal)
    }

    const pushSurveyDataToApi = () => {
        if (props.selectedProject) {
            dispatch(updateProjectTeamCount({
                id: props.selectedProject.id,
                projectName: projectName, 
                month: monthSelected, 
                year: parseInt(yearSelected), 
                count: parseInt(teamCount), 
                createdOn: new Date(),
                updatedBy: fuid
            }))
        }
        else {
            if (projectAlreadyExists()) {
                toast.error('Record already present, please edit if you want to modify')
                return
            }
            dispatch(addProjectTeamCount({
                projectName: projectName, 
                month: monthSelected, 
                year: parseInt(yearSelected), 
                count: parseInt(teamCount), 
                createdOn: new Date(),
                updatedBy: fuid
            }))
        }
    }

    const projectAlreadyExists = () => {
        const matchingProject = props.allProjects.filter(proj => {
            return (proj.projectName === projectName && proj.month === monthSelected && proj.year == yearSelected)
        })

        return matchingProject.length > 0
    }

    const resetState = () => {
        setMonthSelected(monthValue.month)
        setYearSelected(yearValue)
        setProjectSelected(projectName)
        props.hideModal();
    }

    const handleMonthInputChange = (evt) => {
        setMonthSelected(evt.target.value)
    }

    return (<Modal
        isOpen={modal}
        toggle={toggle}
        className={props.className}        >
        <ModalHeader toggle={toggle}><p className="mb-0">Team count <span className="d-block">add team count to project</span></p></ModalHeader>
        <ModalBody>
            <Row>
                <Col xl={12} lg={12} md={12}>
                    <Row>
                        <Col xl={8} lg={8} md={8}>
                            <FormGroup>
                                <Label for="projectSelect">Project</Label>
                                <Input
                                    type="select"
                                    name="Project"
                                    value={projectSelected}
                                    disabled={projectList.length === 0}
                                    onChange={e => setProjectSelected(e.currentTarget.value)} >
                                    {projectList.map(({ projectName }) => <option  key={projectName} value={projectName}>{projectName}</option>)}
                                </Input>
                            </FormGroup>
                            <Row>
                                <Col xl={6} lg={6} md={6}>
                                    <FormGroup>
                                        <Label for="monthSelect">Month</Label>
                                        <Input type="select" name="Month" value={monthSelected} onChange={e => handleMonthInputChange(e)}>
                                            {MONTH_LIST.map(({ month,i }) => <option key={month} value={month}>{month}</option>)}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col xl={6} lg={6} md={6}>
                                    <FormGroup>
                                        <Label for="yearSelect">Year</Label>
                                        <Input type="select" name="Year" value={yearSelected} onChange={e => setYearSelected(e.currentTarget.value)}>
                                            {YEAR_LIST.map((year) => <option  key={year} value={year}>{year}</option>)}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6} lg={6} md={6}>
                                    <FormGroup>
                                        <Label for="projectTeamCount">Count</Label>
                                        <Input
                                            type="text"
                                            name="count"
                                            value={teamCount}
                                            onChange={e => setTeamCount(e.currentTarget.value)} >
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button
                                        disabled={false/*surveyQuestionList.length === 0*/}
                                        color="darkyellow"
                                        size="sm"
                                        className="mr-3"
                                        onClick={pushSurveyDataToApi}>
                                        {props.selectedProject ? 'UPDATE' : 'ADD'}
                                    </Button>
                                    <Button outline color="primary" size="sm" onClick={toggle}>
                                        CANCEL
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </ModalBody>
    </Modal>
    )
}

export default compose(
    firestoreConnect(() => [
        {
            collection: "projects"
        }
    ])
)(CreateTeamCount)