import * as actions from './actionTypes';
import axios from 'axios';
import { toast } from "react-toastify";

const getProjectSuccess = (projects) => {
    return {
        type: actions.GET_PROJECT_SUCCESS,
        projects: projects
    }
}

const getProjectFailure = () => {
    
}

const updateProjectSuccess = () => {
    return {
        type: actions.PROJECT_EDIT_SUCCESS
    }
}

export const getAllProjects = () => {
    return (dispatch,getState,{
        getFirebase
      }) => {
        const firestore = getFirebase().firestore();
        //axios.get('https://khushiapp-dev.firebaseio.com/projects.json')
        firestore.collection("projects").get()
        .then(function(querySnapshot) {
            let projects = []
            querySnapshot.forEach(function(doc) {
                let project = {}
                project = {...doc.data()}
                project.id = doc.id
                projects.push(project)
            })
            dispatch(getProjectSuccess(projects))
        })
        .catch(error => {
            console.log('error ',error);
            //dispatch(getProjectFailure())
        })
    }
}

export const updateUsers = (changedProj) => {
    return (dispatch, state, {getFirebase}) => {

        const firestore = getFirebase().firestore();

        firestore.collection("users").where("project", "==", changedProj.projectName)
        .get()
        .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
                let updatedUser = doc.data()
                updatedUser.manager = changedProj.manager
                firestore.collection("users").doc(doc.id)
                .update(updatedUser)
            })
        })
        .catch(error => {
            console.log(error);
        })
    }
}

export const projectAddStart = (projData) => {
    return (dispatch, state, {getFirebase}) => {

        const firestore = getFirebase().firestore();

        if (projData.id) {
            let projId = projData.id
            delete projData.id
            //axios.patch('https://khushiapp-dev.firebaseio.com/projects/' + projId + '.json', projData)
            firestore.collection("projects").doc(projId)
            .update(projData)
            .then(res => {
                //console.log(res);
                dispatch(updateProjectSuccess())
                dispatch(updateUsers(projData))
                toast.success('Project successfully updated');
            })
            .catch(error => {
                console.log(error);
                toast.error(error);
            })
        }
        else {
            //axios.post('https://khushiapp-dev.firebaseio.com/projects.json', projData)
            firestore.collection("projects")
            .add(projData)
            .then(res => {
                //console.log(res);
                dispatch(updateProjectSuccess())
                toast.success('Project successfully added');
            })
            .catch(error => {
                console.log(error);
                toast.error(error);
            })
        }
        
    }
}

export const projectEditStart = () => {
    return {
        type: actions.PROJECT_EDIT_START
    }
}

export const projectEditEnd = () => {
    return {
        type: actions.PROJECT_EDIT_END
    }
}