import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Page from '../../components/Page';
import { Container, Table, Button, Form } from 'react-bootstrap';
import AdminTabs from '../../components/Layout/AdminTabs';
import CreateTeamCount from './CreateTeamCount';
import { deleteProjectTeamCount } from '../../store/actions/dashboardPage';

import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
} from 'reactstrap';

const ProjectsTeamCount = (props) => {
    const [query, setQuery] = useState("")
    const teamCountData = useSelector(state => state.firestore.ordered.teamCount)
    const [teamCountList, setTeamCountList] = useState(teamCountData)
    const [teamCountFilterList, setTeamCountFilterList] = useState([])
    const [isGenerateVisible, setIsGenerateVisible] = useState(false)
    const [selectedProject, setSelectedProject] = useState(null)

    const dispatch = useDispatch()

    const openGenerateSurvey = (project) => {
        setIsGenerateVisible(true);
        setSelectedProject(project)
    };

    const hideModal = () => {
        setIsGenerateVisible(false);
    }

    useEffect(() => {
        const strQuery = query
        if (teamCountList !== undefined) {
            if (strQuery.length === 0) {
                setTeamCountFilterList(teamCountList)
            } else {
                let filterData = teamCountList.filter(teamCount => {
                    return (teamCount.projectName.toLowerCase().includes(strQuery.toLowerCase())
                        || teamCount.month.toLowerCase().includes(strQuery.toLowerCase())
                        || teamCount.year.toString().includes(strQuery.toLowerCase())
                        || teamCount.count.toString().includes(strQuery.toLowerCase())
                    )
                })
                setTeamCountFilterList(filterData)
            }
        }
    }, [query])

    useEffect(() => {
        setTeamCountList(teamCountData)
        setTeamCountFilterList(teamCountData)
    }, [teamCountData])
    const deleteTeamCount = (project) => {
        var r = window.confirm('Are you sure?');
        if (r == true) {
            dispatch(deleteProjectTeamCount(project))
        }
        
    }

    let rows = []
    if (teamCountFilterList !== undefined) {
        rows = teamCountFilterList.map((project, index) => {
            return (
                <tr key={project.id}>
                    <td>{project.projectName}</td>
                    <td>{project.month}</td>
                    <td>{project.year}</td>
                    <td>{project.count}</td>
                    <td>
                        <div style={{ overflow: 'hidden' }}>
                            <Button style={{ 'float': 'left', 'marginLeft': '10px' }} variant="primary" size="sm p-1" onClick={() => openGenerateSurvey(project)}>Edit</Button>
                            <Button style={{ 'float': 'left', 'marginLeft': '10px' }} variant="primary" size="sm p-1" onClick={() => deleteTeamCount(project)}>Delete</Button>
                        </div>
                    </td>
                </tr>
            )
        })
    }

    const inputTextChange = event => {
        setQuery(event.target.value)
    }

    let editModel = null
    if (teamCountData) {
        //editModel = <EditProjects show={props.editorMode} handleClose={this.closeEditor} project={this.state.editProject} takenNames={props.projects.map(proj => proj.projectName.toLowerCase())} />
    }

    return (
        <Page className="QuestionPage">
            <AdminTabs />
            <Row>
                <Col>
                    <Card className="shadow p-3 mb-5 bg-white rounded-0 border-0">
                        <CardBody className="position-relative">
                            {editModel}
                            <Button className="btn-darkyellow mb-3" size="sm" onClick={()  => openGenerateSurvey(null)}>Add new</Button>
                            <input type="text" className="mr-sm-2 font-24px mt-3 mb-3 form-control bg-light border-0 small" placeholder="Search"
                                value={query}
                                onChange={e => inputTextChange(e)}
                                aria-label="Search" aria-describedby="basic-addon2" />
                            <div className="table-responsive">
                                <Table striped hover>
                                    <thead>
                                        <tr>
                                            <th>Project Name</th>
                                            <th>Month</th>
                                            <th>Year</th>
                                            <th>Count</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows}
                                    </tbody>
                                </Table>
                                {/* <Button outline color="primary" size="sm" onClick={() => props.history.goBack()}>CANCEL</Button> */}
                            </div>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <CreateTeamCount hideModal={hideModal} isGenerateVisible={isGenerateVisible} selectedProject={selectedProject} allProjects={teamCountData} className="GenerateSurveyPage" />
        </Page>
    )
}


export default compose(
    firestoreConnect(() => [
        {
            collection: "teamCount",
            orderBy: ["createdOn", "desc"]
        },
    ])
)(ProjectsTeamCount);