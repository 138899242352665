import firebase from "firebase/app"
import 'firebase/firestore'

// var firebaseConfig = {
//     apiKey: "AIzaSyB8wLqgPI1WYFiP3xzo3FlYZKAoVo4KjS8",
//     authDomain: "khushiapp-dev.firebaseapp.com",
//     databaseURL: "https://khushiapp-dev.firebaseio.com",
//     projectId: "khushiapp-dev",
//     storageBucket: "khushiapp-dev.appspot.com",
//     messagingSenderId: "649478624342",
//     appId: "1:649478624342:web:41f76dc0be8917f18357f7",
//     measurementId: "G-T9QQDBNK48"
// };

var firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DATABASEURL,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.firestore();

//firebase.analytics()

export default firebase

