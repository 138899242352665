import firebase from 'firebase';
import axios from 'axios';
import * as actions from './actionTypes';

export const authFormShow = (token, userData) => {
    return {
        type: actions.AUTHENTICATION_SUCCESS,
        token: token,
        userData: userData
    }
}

const authSuccess = (token, userData) => {

    return (dispatch,getState,{
        getFirebase
      }) => {
        const firestore = getFirebase().firestore();
        //const queryParam = '?orderBy="uid"&equalTo="' + userData.uid + '"';
        //axios.get('https://khushiapp-dev.firebaseio.com/users.json' + queryParam)
        firestore.collection("users").where("uid", "==", userData.uid).get()
        .then(function(querySnapshot) {
            let users = []
            let id = null
            querySnapshot.forEach(function(doc) {
                id = doc.id
                users.push(doc.data())
            })
            if (users.length > 0) {
                let user = users[0]
                userData.fuid = id
                userData.designation = user.designation
                userData.empId = user.empId
                userData.role = user.role
                userData.manager = user.manager
                userData.project = user.project
                userData.isActive = user.isActive

                localStorage.setItem("storedUser", JSON.stringify(userData))
            }
            localStorage.setItem("storedToken", token)

            dispatch(authFormShow(token, userData))
        })
        .catch(err => {
            console.log(err)
        })
    }
}

const authFailure = (error) => {
    return {
        type: actions.AUTHENTICATION_FAILED,
        error: error
    }
}

export const authStart = () => {
    
    var provider = new firebase.auth.OAuthProvider('microsoft.com');
        
    provider.setCustomParameters({
        tenant: '19c14640-100f-4ce7-b578-2cf1b2ea3973'
    });

    return dispatch => {

        firebase.auth().signInWithPopup(provider)
        .then(function(result) {
            const token = result.credential.idToken
            const userData = {
                uid: result.user.uid,
                email: result.user.email,
                firstName: result.user.displayName.split(' ')[0],
                lastName: result.user.displayName.split(' ')[1]
            }

            dispatch(authSuccess(token, userData));
            // User is signed in.
            // IdP data available in result.additionalUserInfo.profile.
            // OAuth access token can also be retrieved:
            // result.credential.accessToken
            // OAuth ID token can also be retrieved:
            // result.credential.idToken
        })
        .catch(function(error) {
            console.log(error)
            // Handle error.
        });

    }
    
}

export const emailAuthStart = (token, userData) => {
    return dispatch => {
        dispatch(authSuccess(token, userData));
    }
}

export const userLogout = () => {
    return {
        type: actions.USER_LOGOUT
    }
}