import React, { Component } from 'react';
import { connect } from 'react-redux';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
//import Loader from '../../components/Loader/Loader';

import { profileGetManager } from '../../store/actions/users';
import { projectAddStart } from '../../store/actions/projects';

class AddProjects extends Component {

    state = {
        form: {
            projectName: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Project name'
                },
                value: this.props.project ? this.props.project.projectName : '',
                validation: {
                    required: true
                },
                valid: this.props.project && this.props.project.projectName ? true : false,
                touched: false
            },
            description: {
                elementType: 'textarea',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Description'
                },
                value: this.props.project ? this.props.project.description : '',
                validation: {},
                valid: true,
                touched: false
            },
            manager: {
                elementType: 'select',
                elementConfig: {
                    options: this.props.managers
                },
                value: this.props.project ? this.props.project.manager : '',
                validation: {},
                valid: this.props.project && this.props.project.manager,
                touched: false
            },
            active: {
                elementType: 'switch',
                elementConfig: {
                    type: 'switch',
                    id: 'custom-switch',
                    checked: this.props.project && this.props.project.active ? true : false
                },
                validation: {},
                valid: true
            }
        },
        formIsValid: this.props.project && this.props.project.projectName && this.props.project.manager
    }

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }
        
        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }

    inputChangedHandler = (event, inputIdentifier) => {
        let elementProp = {}
        if (inputIdentifier === 'active') {
            elementProp = {
                elementConfig: {
                    ...this.state.form[inputIdentifier].elementConfig,
                    checked: !this.state.form[inputIdentifier].elementConfig.checked
                }
            }
        }
        else {
            elementProp = {
                value: event.target.value
            }
        }
        
        const updatedOrderForm = {
            ...this.state.form,
            [inputIdentifier]: {
                ...this.state.form[inputIdentifier],
                ...elementProp,
                valid: this.checkValidity(event.target.value, this.state.form[inputIdentifier].validation),
                touched: true
            }

        };

        let formIsValid = true
        for (let field in updatedOrderForm) {
            formIsValid = updatedOrderForm[field].valid && formIsValid
        }
    
        this.setState({form: updatedOrderForm, formIsValid: formIsValid});
    }

    updateProject = (event) => {
        event.preventDefault();

        const projDetails = {}

        for (let field in this.state.form) {
            projDetails[field] = field === 'active' ? this.state.form[field].elementConfig.checked : this.state.form[field].value
        }

        let isNameUnique = true

        if (this.props.project == null) {
            projDetails['created_date'] = new Date()

            if (this.props.takenNames.includes(projDetails['projectName'].toLowerCase())) {
                isNameUnique = false
            }

        }
        else {
            projDetails['id'] = this.props.project.id
            if (projDetails['projectName'].toLowerCase() !== this.props.project.projectName.toLowerCase() && this.props.takenNames.includes(projDetails['projectName'].toLowerCase())) {
                isNameUnique = false
            }
        }

        if (!isNameUnique) {
            alert("Project name is already taken")
            return
        }
            
        

        this.props.projectAdd(projDetails);

    }

    componentDidMount() {

        this.props.getManagers();

        if (this.props.project) {
            const formsControl = {
                ...this.state.form
            }
            formsControl['projectName']['value'] = this.props.project.projectName
            formsControl['description']['value'] = this.props.project.description
            this.state.formIsValid = true
            this.setState({'form': formsControl})
        }
    }

    componentDidUpdate() {
        //console.log('this.props.project', this.props.project)

        if (this.props.redirect === 'projects') {
            //this.props.history.push('projects')
        }

    }

    componentWillReceiveProps(nextProps) {
        //console.log('componentWillReceiveProps', nextProps.managers);
        const managersControl = {
            ...this.state.form.manager
        }

        const formsControl = {
            ...this.state.form
        }

        formsControl['manager'] = managersControl

        managersControl['elementConfig']['options'] = nextProps.managers

        if (this.props.project) {
            managersControl['value'] = this.props.project.manager
        }

        this.setState({'form': formsControl})
    }

    render() {
        
        const formElementsArray = [];
        for (let key in this.state.form) {
            //console.log(key, 'this.props.managers', this.state.form[key])
            formElementsArray.push({
                id: key,
                config: this.state.form[key]
            });
        }
        let form = formElementsArray.map(formElement => (
            <Input 
                key={formElement.id}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={(event) => this.inputChangedHandler(event, formElement.id)}  />
        ));
        const button = this.props.isLoading ? <div>Loading...</div> : <Button btnType="btn-darkyellow btn btn-primary btn-sm"  disabled={!this.state.formIsValid}>{this.props.project ? 'Update' : 'Add'}</Button>
        let className = this.props.className ? this.props.className : "Full"
        return(
            <div className={className}>
                <form onSubmit={this.updateProject}>
                    {form}
                    {button}
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        managers: state.users.managers,
        redirect: state.projects.redirect
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getManagers: () => dispatch(profileGetManager()),
        projectAdd: (projDetails) => dispatch(projectAddStart(projDetails))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddProjects);