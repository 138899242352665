import { toast } from "react-toastify";

export const createQuestion = (question) => {
  return (dispatch, getState, {
    getFirebase
  }) => {
    const firestore = getFirebase().firestore();
    firestore.collection("questions")
        .add({
          ...question,
          createdOn: new Date()
        })
        .then(() => {
          dispatch({
            type: 'CREATE_QUESTION_SUCCESS',
            question
          });
          toast.success('Question successfully added');
        })
        .catch((err) => {
          dispatch({
            type: 'CREATE_QUESTION_ERROR',
            err
          });
          toast.error(err);
        });
    // if (question.id) {
    //   let questionID = question.id
    //   delete question.id
    //   firestore.collection("questions").doc(questionID)
    //     .update({
    //       title: question.title,
    //       description: question.description,
    //       createdBy: question.createdBy
    //     })
    //     .then(res => {
    //       console.log(res);
    //       dispatch({
    //         type: 'EDIT_QUESTION_SUCCESS',
    //         question
    //       });
    //     })
    //     .catch(err => {
    //       console.log(err);
    //       dispatch({
    //         type: 'EDIT_QUESTION_ERROR',
    //         err
    //       });
    //     })
    // } else {
      
    // }

  }
};



export const editQuestion = (question, id) => {
  // console.log("editQuestion",  question , id)
  return (dispatch, state, {getFirebase}) => {
    // console.log(dispatch)
    const firestore = getFirebase().firestore();
    firestore.collection("questions").doc(question.id).update(question)
      .then(() => {
        dispatch({
          type: 'EDIT_QUESTION_SUCCESS',
          question
        });
        toast.success('Question successfully updated');
      })
      .catch(err => {
          dispatch({
            type: 'EDIT_QUESTION_ERROR',
            err
          });
          toast.error(err);
      })
  }
};

// export const removeQuestion = (question) => {
//   return (dispatch, getState, {
//     getFirebase
//   }) => {
//     const firestore = getFirebase().firestore();
//     firestore
//       .collection("questions")
//       .doc(question.id)
//       .delete()
//       .then(() => {
//         dispatch({
//           type: "REMOVE_QUESTION",
//         });
//       })
//       .catch((err) => {
//         dispatch({
//           type: "REMOVE_QUESTION_ERR",
//           err,
//         });
//       });
//   };
// };