import Page from 'components/Page';
import React from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
    Form,
    FormGroup,
    FormText,
    /*Input,*/
    Label,
} from 'reactstrap';

import { profileSubmitStart, profileSet } from '../store/actions/profile';
import { profileGetManager } from '../store/actions/users';
import { getAllProjects } from '../store/actions/projects';
import { refreshDashboard } from '../store/actions/dashboardPage';
import { toast } from "react-toastify";
import Input from '../components/Input/Input';
import { PROFILE_ROLE_LIST, ACCESS_RIGHTS } from '../constants'

class ProfilePage extends React.Component {


    getDatasource = (keyName) => {
        if (this.props.user) {
            return this.props.user[keyName]
        }
        else if (this.props.userData && this.props.userData[keyName] !== undefined) {
            return this.props.userData[keyName]
        }
        return ''
    }

    getRoles = () => {
        return PROFILE_ROLE_LIST.map(role => { return {value: role, displayValue: role} })
    }

    getAccessRights = () => {
        return ACCESS_RIGHTS.map(role => { return {value: role, displayValue: role} })
    }

    state = {
        form: {
            firstName: {
                label:'First name',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'First name',
                    disabled: true,
                },
                value: this.getDatasource('firstName'),
                validation: {
                    required: true
                },
                valid: this.getDatasource('firstName') ? true : false,
                touched: this.getDatasource('firstName') ? true : false,
                noteConfig: {
                    note: 'Your First Name as provided in Office 365',
                    visibility: true
                } 
            },
            lastName: {
                label:'Last name',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Last name',
                    disabled: true
                },
                value: this.getDatasource('lastName'),
                validation: {
                    required: true
                },
                valid: this.getDatasource('lastName') ? true : false,
                touched: this.getDatasource('lastName') ? true : false,
                noteConfig: {
                    note: 'Your Last Name as provided in Office 365',
                    visibility: true
                } 
            },
            designation: {
                label:'Role',
                elementType: 'select',
                elementConfig: {
                    options: [
                        {value: '', displayValue: 'Select role', disabled: true, selected: true},
                        ...this.getRoles()
                    ],
                    disabled: this.getDatasource('fuid') != '' && this.props.userData['role'] !== 'Admin'
                },
                value: this.getDatasource('designation'),
                validation: {},
                valid: this.getDatasource('designation') ? true : false,
                touched: this.getDatasource('designation') ? true : false,
                noteConfig: {
                    note: 'Select your current Role with Arrk',
                    visibility: true
                } 
            },
            email: {
                label:'Email',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Email',
                    disabled: true
                },
                value: this.getDatasource('email'),
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: this.getDatasource('email') ? true : false,
                touched: this.getDatasource('email') ? true : false,
                noteConfig: {
                    note: 'Your official Email ID associated in Office 365',
                    visibility: true
                } 
            },
            role: {
                label:'Access right',
                elementType: 'select',
                elementConfig: {
                    options: [
                        {value: '', displayValue: 'Select access right', disabled: true, selected: true},
                        ...this.getAccessRights()
                    ],
                    disabled: this.getDatasource('fuid') != '' && this.props.userData['role'] !== 'Admin'
                },
                value: this.getDatasource('role') === '' ? 'Employee' : this.getDatasource('role'),
                validation: {},
                valid: true,//this.getDatasource('role') ? true : false,
                touched: this.getDatasource('role') ? true : false,
                noteConfig: {
                    note: '',
                    visibility: false
                } 
            },
            empId: {
                label:'Employee id',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Employee Id',
                    disabled: false
                },
                value: this.getDatasource('empId'),
                validation: {
                    required: true,
                    maxLength: 10,
                    name: 'empId'
                },
                valid: this.getDatasource('empId') ? true : false,
                touched: this.getDatasource('empId') ? true : false,
                noteConfig: {
                    note: 'Expected format e.g. AINE0001, AUKE0001',
                    visibility: true
                }
            },
            project: {
                label:'Project',
                elementType: 'select',
                elementConfig: {
                    options: this.props.projects,
                    disabled: false
                },
                value: this.getDatasource('project'),
                validation: {},
                valid: this.getDatasource('project') ? true : false,
                touched: this.getDatasource('project') ? true : false,
                noteConfig: {
                    note: 'Select your Current project with Arrk. NOTE: If you associated with more than one project then you may need to change this field value everytime in order to appear survey of other projects',
                    visibility: true
                } 
            },
            manager: {
                label:'Manager',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'NA',
                    disabled: true
                },
                value: '',
                validation: {},
                valid: true,
                touched: true,
                noteConfig: {
                    note: '',
                    visibility: false
                } 
            }
            /*manager: {
                label:'Manager',
                elementType: 'select',
                elementConfig: {
                    options: this.props.managers,
                    disabled: this.getDatasource('role') === 'Manager' || (this.getDatasource('fuid') != '' && this.props.userData['role'] !== 'Admin')
                },
                value: this.getDatasource('manager'),
                validation: {},
                valid: this.getDatasource('manager') ? true : false,
                touched: this.getDatasource('manager') ? true : false
            },*/
            // isActive: {
            //     label:'Status',
            //     elementType: 'select',
            //     elementConfig: {
            //         options: [
            //             {value: 1, displayValue: 'Active'},
            //             {value: 0, displayValue: 'Inactive'}
            //         ],
            //         //disabled: this.getDatasource('fuid') != '' && this.props.userData['role'] !== 'Admin'
            //         disabled: true
            //     },
            //     value: this.getDatasource('isActive') ? this.getDatasource('isActive') : 1,
            //     validation: {},
            //     valid: true,
            //     touched: this.getDatasource('isActive') ? true : false,
            //     noteConfig: {
            //         note: 'Your current employment Status with Arrk',
            //         visibility: true
            //     } 
            // }
        },
        formIsValid: this.getDatasource('firstName') && this.getDatasource('lastName') && this.getDatasource('email') && /*this.getDatasource('designation') (this.getDatasource('role') === 'Manager' || this.getDatasource('manager') &&*/ this.getDatasource('role') && this.getDatasource('project')
    }


    checkValidity(value, rules) {

        let isValid = true;
        if (!rules) {
            return true;
        }
        
        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.name === 'empId') {
            const strFirstFour = value.trim().substring(0,4);
            const strSecondlayer = value.trim().substring(4,10);
            if(strFirstFour === 'AINE' || strFirstFour === 'AUKE') {
                //isValid = true
                isValid = /^\d+$/.test(strSecondlayer) && isValid
            } else {
                isValid = false
                toast.error('Please type correct format', {
                    toastId: 'errorEmpId'
                })
            }
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
            if (rules.name === 'empId' && value.length > rules.maxLength) {
                toast.error('Maximum 10 letters allowed', {
                    toastId: 'errorEmpMaxLength'
                })
            }
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }

    

    inputChangedHandler = (event, inputIdentifier) => {
        //console.log(inputIdentifier + '---- ' + event.target.value);

        const updatedOrderForm = {
            ...this.state.form,
            [inputIdentifier]: {
                ...this.state.form[inputIdentifier],
                value: event.target.value,
                valid: this.checkValidity(event.target.value, this.state.form[inputIdentifier].validation),
                touched: true 
            }
        };

        let managerField = null

        /*if (inputIdentifier === 'role') {
            if (event.target.value === 'Manager') {
                managerField = {
                    ...this.state.form['manager'],
                    ['elementConfig']: {
                        ...this.state.form['manager']['elementConfig'],
                        ['disabled']: true
                    },
                    'value': ''
                }
            }
            else {
                managerField = {
                    ...this.state.form['manager'],
                    ['elementConfig']: {
                        ...this.state.form['manager']['elementConfig'],
                        ['disabled']: false
                    },
                    value: this.getDatasource('manager')
                }
            }
            updatedOrderForm['manager'] = managerField
        }*/
        if (inputIdentifier === 'project') {
            
            managerField = {
                ...this.state.form['manager'],
                'value': this.props.projects.filter(proj => proj.displayValue === updatedOrderForm['project'].value)[0].manager
            }

            updatedOrderForm['manager'] = managerField
        }
        /*else if (inputIdentifier === 'project') {
            managerField = {
                ...this.state.form['manager'],
                ['elementConfig']: {
                    ...this.state.form['manager']['elementConfig'],
                    ['disabled']: false
                },
                value: this.props.managers.filter(manager => manager.value)
            }
        }*/

        let formIsValid = true
        for (let field in updatedOrderForm) {
            formIsValid = (updatedOrderForm[field].elementConfig.disabled || updatedOrderForm[field].valid) && formIsValid//updatedOrderForm[field].elementConfig.disabled || (updatedOrderForm[field].valid && formIsValid)
            //console.log('form is valid ' + field + ' - ', + formIsValid + ' - ' + updatedOrderForm[field].valid)
        }
    
        this.setState({form: updatedOrderForm, formIsValid: formIsValid});
    }


    updateUser = (event) => {
        
        event.preventDefault();
        const userDetails = {};
        let userDetail = JSON.parse(localStorage.getItem("storedUser"));
        let userStatus = userDetail ? userDetail["isActive"] ? userDetail["isActive"] : false : true;
 
        for (let field in this.state.form) {
            userDetails[field] = this.state.form[field].value
            //console.log(this.state.form[field] + ' -- ' + this.state.form[field].value)
        }

        userDetails['isActive'] = userStatus ? userStatus : ''
        userDetails['uid'] = this.getDatasource('uid')
        
        if (this.getDatasource('fuid') != '') {
            userDetails['fuid'] = this.getDatasource('fuid')
        }
        
        this.props.profileSubmit(userDetails, this.props.userData.fuid);
        this.props.refreshDashboard()
    }

    componentDidMount() {
        this.props.getManagers();
        this.props.getProjects();
    }

    componentDidUpdate() {
        //console.log('get status', this.getDatasource('role'))
        if (this.props.completed) {
            //this.props.history.push('users')
            this.props.history.push('/')
            this.props.redirectionCompleted()
        }
    }

    componentWillReceiveProps(nextProps) {
        //console.log('nextProps.manager ' + nextProps.managers + ' nextProps.projects ' + nextProps.projects)
        const managersControl = {
            ...this.state.form.manager
        }
        const formsControl = {
            ...this.state.form
        }
        formsControl['manager'] = managersControl
        managersControl['elementConfig']['options'] = nextProps.managers
        managersControl['value'] = this.props.projects && this.props.projects.length > 0 && this.getDatasource('project') ? this.props.projects.filter(proj => proj.displayValue === this.getDatasource('project'))[0].manager : ''

        const projectsControl = {
            ...this.state.form.project
        }
        formsControl['project'] = projectsControl
        projectsControl['elementConfig']['options'] = nextProps.projects
        
        //console.log('formsControl ',formsControl)

        this.setState({'form': formsControl})
    }

    render() {
        const formElementsArray = [];
        for (let key in this.state.form) {
            formElementsArray.push({
                id: key,
                config: this.state.form[key]
            });
        }
        let form = formElementsArray.map(formElement => (
            (this.props.userData.role === 'Admin' || formElement.config.label !== 'Access right') && formElement.config.label !== 'Manager' ? <Input 
                key={formElement.id}
                label={formElement.config.label}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={(event) => this.inputChangedHandler(event, formElement.id)}
                noteSettings={formElement.config.noteConfig}/> : null
        ));
        
        const button = this.props.isLoading ? <div>Updating...</div> : <Button color="darkyellow" size="sm" className="mr-3" disabled={!this.state.formIsValid}>SAVE</Button>
        //let className = this.props.className ? this.props.className : "Full"
          
        return(
            <Page className="ProfilePage">
                <div className="profileContent">
                    <Card className="cardBox">
                        <CardHeader><h2 className="mb-0">My Profile <span className="d-block">Update your information</span></h2></CardHeader>
                        <CardBody>
                            <form onSubmit={this.updateUser}>
                                {form}
                                <div class="form-group"><label>Status:</label> {((this.props.userData["isActive"] === true) ? "Active" : "Inactive")}</div>
                                {button}
                            </form>
                        </CardBody>
                    </Card>
                    </div>
                </Page>
            
        );
    }
}


const mapStateToProps = state => {
    return {
        userData: state.auth.userData,
        isLoading: state.profile.isLoading,
        managers: state.users.managers,
        projects: state.projects.projects,
        completed: state.profile.completed
    }
}

const mapDispatchToProps = dispatch => {
    return {
        profileSubmit: (userDetails, fuid) => dispatch(profileSubmitStart(userDetails, fuid)),
        getManagers: () => dispatch(profileGetManager()),
        getProjects: () => dispatch(getAllProjects()),
        redirectionCompleted: () => dispatch(profileSet()),
        refreshDashboard: () => dispatch(refreshDashboard())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);