import React, { useState, useEffect } from 'react'
import Page from '../../components/Page';
import Question from './Question'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import CreateQuestion from './CreateQuestion'
import AdminTabs from '../../components/Layout/AdminTabs';
import { Container, Table, Button, Form } from 'react-bootstrap';
import moment from 'moment';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from 'reactstrap';

const QuestionList = ({ questions }) => {

  const [query, setQuery] = useState("")
  const [questionList, setQuestionList] = useState(questions)
  const [questionFilterList, setQuestionFilterList] = useState(questions)

  useEffect(() => {
    setQuestionList(questions)
    setQuestionFilterList(questions)
  }, [questions])
  
  useEffect(() => {
    const strQuery = query
    if (questionList !== undefined) {
      if (strQuery.length === 0) {
        setQuestionFilterList(questionList)
      } else {
        let filterData = questionList.filter(question => {
          return (question.title.toLowerCase().includes(strQuery.toLowerCase())
            || question.description.toLowerCase().includes(strQuery.toLowerCase())
            || moment(question.createdOn.toDate()).format('DD-MMM-YYYY').toLowerCase().includes(strQuery.toLowerCase())
          )
        })
        setQuestionFilterList(filterData)
      }
    }
  }, [query])
  // console.log(questions);

  const inputTextChange = event => {
    setQuery(event.target.value)
  }


  return (
    <Page className="QuestionPage">
      <AdminTabs />
      <Row>
        <Col>
          <Card className="shadow p-3 mb-5 bg-white rounded-0 border-0">
            <CardBody className="position-relative">
              <CreateQuestion className="sideModal" />
              <input type="text" className="mr-sm-2 font-24px mt-3 mb-3 form-control bg-light border-0 small" placeholder="Search"
                value={query}
                onChange={e => inputTextChange(e)}
                aria-label="Search" aria-describedby="basic-addon2" />
              <div className="table-responsive">
                <Table striped hover>
                  <thead>
                    <tr>
                      <th scope="col">Title</th>
                      <th scope="col">Content</th>
                      <th scope="col">Created date</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {questionFilterList && questionFilterList.map((question, i) => {
                      return (
                        <React.Fragment key={i}>
                          <Question question={question} />
                        </React.Fragment>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  )
}


const mapStateToProps = (state) => {
  // console.log('state.firestore.ordered.questions ', state.firestore.ordered.questions);
  const questions = state.firestore.ordered.questions;
  return {
    questions: questions,

  };
};
export default compose(
  connect(mapStateToProps),
  firestoreConnect(() => [
    {
      collection: "questions",
      orderBy: ["createdOn", "desc"]
    },
  ])
)(QuestionList);

