import React from 'react';
import { userLogout } from '../store/actions/auth';
import { resetRefreshDashboard } from '../store/actions/dashboardPage';
import { connect } from 'react-redux' 

const Logout = (props) => {
    localStorage.removeItem('storedUser')
    localStorage.removeItem('storedToken')
    props.logout()
    return(
        <div>Logging out....</div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(userLogout()),
        resetRefreshState: () => dispatch({type: 'RESET_REFRESH_DASHBOARD'})
    }
} 

export default connect(null,mapDispatchToProps)(Logout)