import * as actions from '../actions/actionTypes'
const initialState = {
    isLoading: false,
    error: null,
    completed: false,
    isEditorMode: false
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.PROFILE_SUBMIT_START:
            return {
                isLoading: true
            }
        case actions.PROFILE_SUBMIT_SUCCESS:
            return {
                isLoading: false,
                completed: true,
                error: null,
                isEditorMode: false
            }
        case actions.PROFILE_SUBMIT_FAILED:
            return {
                isLoading: false,
                completed: false,
                error: action.error
            }
        case actions.PROFILE_EDIT_START:
            return {
                isEditorMode: true
            }
        case actions.PROFILE_EDIT_END:
            return {
                isEditorMode: false
            }
        case actions.PROFILE_ALREADY_SET:
            return {
                completed: false
            }
        default:
            return state
    }
 }

 export default reducer