import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  Row,
  FormGroup,
  Input,
  Label,
  Container,
  Table,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';
import Page from '../../components/Page';
import DisplayReport from "../Reports/DisplayReport";
import { connect } from 'react-redux'
import { useSelector, useDispatch } from 'react-redux'
import { firestoreConnect, useFirestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import AdminTabs from '../../components/Layout/AdminTabs';
import moment from "moment";
import { YEAR_LIST, MONTH_LIST } from '../../utils/constants';


const GenerateReport = () => {

  const monthValue = MONTH_LIST.filter(key => key.month === moment(new Date()).format("MMM"))[0]
  const yearValue = YEAR_LIST.filter(key => key == moment(new Date()).format("YYYY"))[0]
  const userData = useSelector(state => state.auth.userData)
  const [projectList, setProjectList] = useState([])
  const projects = useSelector(state => state.firestore.ordered.projects)
  const projectName = (projectSelected === '' && projectList.length > 0) ? projectList[0].projectName : projectSelected
  const myProjects = projects.filter(key => key.manager === userData.fuid)
  const [projectSelected, setProjectSelected] = useState("")
  const [monthSelected, setMonthSelected] = useState("")
  const [yearSelected, setYearSelected] = useState(yearValue)
  useEffect(() => {
    if (userData.role == "Manager"){
      if (myProjects !== undefined){
        setProjectList(myProjects)
      }
    }
    if (userData.role == "Admin"){
      if (projects !== undefined){
        setProjectList(projects)
      }
    }
  }, [])
  const handleMonthInputChange = (evt) => {
    setMonthSelected(evt.target.value)
  }
  useFirestoreConnect([
    {
      collection: "projects"
    }
  ])


  const onSubmit = (data) => {
    // //console.log(data);

    //console.log(report)
  }
  let fb;

  const handleSubmit = (evt) => {
    evt.preventDefault();
    //console.log(projectSelected + "-" + monthSelected + "-" + yearSelected)
    fb = projectSelected + "-" + monthSelected + "-" + yearSelected
    // return fb
    //console.log(fb)
    if (fb !== undefined) {
      useFirestoreConnect([
        {
          collection: 'feedback',
          where: ['surveyName', '==', fb],//projectSelected+"-"+monthSelected+"-"+yearSelected],
          storeAs: "report"
        }
      ])
    }
  }
  const report = useSelector(state => state.firestore.ordered.report)

  return (
    <Page className="ReportPage">
      <AdminTabs />

      <Row>
        <Col>
          <Card className="shadow p-3 mb-5 bg-white rounded-0 border-0">
            <CardBody className="position-relative">
              <form onSubmit={handleSubmit} className="d-flex">

                <FormGroup className="mr-3">
                  <Label for="monthSelect">Project</Label>

                  <select name="Project" className="form-control"
                    value={projectSelected}
                    disabled={projectList.length === 0}
                    onChange={e => setProjectSelected(e.target.value)}>
                    <option value="none">Please select Project
                </option>
                    {projectList.map(({ projectName }) => <option key={projectName} value={projectName}>{projectName}</option>)}
                  </select>
                </FormGroup>
                <FormGroup className="mr-3">
                  <Label for="monthSelect">Month</Label>
                  {/* <Input type="select" name="Month" value={monthSelected} onChange={e => handleMonthInputChange(e)}>
                    {MONTH_LIST.map(({ month,i }) => <option key={month} value={month}>{month}</option>)}
                </Input> */}
                  <select className="form-control"
                    name="Month" value={monthSelected} onChange={e => handleMonthInputChange(e)}>
                    <option value="none">Please select month
                </option>
                    {MONTH_LIST.map(({ month, i }) => <option key={month} value={month}>{month}</option>)}
                  </select>

                </FormGroup>
                <FormGroup className="mr-3">
                  <Label for="yearSelect">Year</Label>
                  <Input type="select" name="Year" value={yearSelected} onChange={e => setYearSelected(e.currentTarget.value)}>
                    {YEAR_LIST.map((year) => <option key={year} value={year}>{year}</option>)}
                  </Input>
                </FormGroup>
              </form>

              {/* <div className="projectNameBox px-3 py-3 d-flex justify-content-between align-items-center">
                            <h2 className="mb-0">{projectSelected}</h2>
                            <h3 className="mb-0">{monthSelected + ' ' + yearSelected}</h3>
                        </div> */}
              <DisplayReport project={projectSelected} month={monthSelected} year={yearSelected} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  )
}


export default GenerateReport;